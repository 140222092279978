import React, { useContext, useState, useEffect, useMemo, useCallback } from "react";
import { FormContext, metaBuyingFormDefault, metaCampaignFormDefault } from "../MetaBuyingContext";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { MetaBuyingNamePreview } from "./MetaBuyingNamePreview";
import { MetaBuyingOptionsNoteCard } from "./MetaBuyingOptionsNoteCard";
import { MetaBuyingFooterControls } from "./MetaBuyingFooterControls";
import { RouteComponentProps } from "@reach/router";
import { Button, ButtonType, SingleDatePicker } from "../../Components";
import { ButtonFrameworkVariant } from "../../Components/ButtonFramework";
import {
  filterSegmentationIds,
  generateNameFields,
  filterInheritedFields,
  formatAge,
  mapRowsByProp,
  mapLabelsByValues,
  validateName,
  formatBudget,
  updateBudget,
  getAttributionOptions,
  removeIsoTZ,
} from "../MetaBuyingUtils";
import { MetaBuyingTabs } from "../MetaBuyingTabs";
import {
  Dimension,
  CreationTab,
  FormField,
  CampaignRow,
  SpecialAdCategory,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import { HiPlus } from "react-icons/hi";
import { MetaBuyingPlacements } from "./MetaBuyingPlacements";
import { awaitJSON, MetaLambdaFetch } from "../../utils/fetch-utils";
import { useSetError } from "../../redux/modals";
import moment from "moment-timezone";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import MetaBuyingNameFields from "./MetaBuyingNameFields";
import { SingleSelectMenu, MultiSelectMenu, DROPDOWN_BTN } from "./MetaBuyingSelectMenu";
import { TODAY } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import {
  TAB_AD_SET,
  CAMPAIGN,
  AD_SET,
  AD,
  PURCHASE,
  OFFSITE_CONVERSIONS,
  VALUE,
  IMPRESSIONS,
  TAB_CAMPAIGN,
  OUTCOME_SALES,
  LOWEST_COST_WITHOUT_CAP,
  LOWEST_COST_WITH_BID_CAP,
  LOWEST_COST_WITH_MIN_ROAS,
  COST_CAP,
  CAMPAIGN_DAILY_BUDGET,
  CAMPAIGN_LIFETIME_BUDGET,
  AD_SET_DAILY_BUDGET,
  AD_SET_LIFETIME_BUDGET,
  STATUS_NONE,
  CONVERSION_EVENT_OPTIONS,
  CONVERSION_GOAL_OPTIONS,
  BID_STRATEGIES,
  AUDIENCE_SUBTYPE_MAP,
  OPTIONS_NOTE_CARD_HEADERS,
  OPTIONS_NOTE_CARD_TEXTS,
  OPTIONS_NOTE_CARD_OPTIONS,
  BUDGET_TYPES,
  AGE_OPTIONS,
  GENDER_OPTIONS,
  SPECIAL_AD_CATEGORIES,
} from "../MetaBuyingConstants";

interface MetaBuyingAdSetProps {
  client: string;
  namingConventions: {
    [CAMPAIGN]: number[];
    [AD_SET]: number[];
    [AD]: number[];
  };
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  showReviewModal: () => void;
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  validateForms: (
    metaBuyingForm: typeof metaBuyingFormDefault,
    navigate: () => void,
    nameFields: Record<string, any>,
    tabToValidate?: CreationTab
  ) => void;
  creationTabs: CreationTab[];
  granularity: string;
  dimensions: Record<string, any>;
  allNameFields: Record<string, any>;
  campaigns: CampaignRow[];
  customAudiences: { custom_audience_id: string; name: string }[];
  pixels: { pixel_id: string; name: string }[];
  email: string;
  saveNameFields: (nameFields: Record<string, any>) => void;
}

const { SEGMENTATION_ID } = Dimension;

export const MetaBuyingAdSet = ({
  client,
  selectedAdAccount,
  namingConventions,
  tabIndex,
  setTabIndex,
  showReviewModal,
  validateForms,
  creationTabs,
  granularity,
  dimensions,
  allNameFields,
  campaigns,
  customAudiences,
  pixels,
  email,
  saveNameFields,
}: MetaBuyingAdSetProps & RouteComponentProps): JSX.Element => {
  const { metaBuyingForm, setMetaBuyingForm } = useContext(FormContext);

  const metaAdSetForm = metaBuyingForm.ad_set;
  const metaCampaignForm = metaBuyingForm.campaign;

  const namingConvention = namingConventions[AD_SET];

  const setError = useSetError();

  const { account_id, business_manager } = selectedAdAccount;

  const DATE_FORMAT = "yyyy-MM-dd h m a xx";
  const DEFAULT_START = Dfns.format(DATE_FORMAT, new Date()).split(" ");
  const DEFAULT_END = Dfns.format(
    DATE_FORMAT,
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  ).split(" ");

  const CAMPAIGN_MENU_ID = "campaignMenu";
  const INCL_AUD_MENU_ID = "inclAudMenu";
  const EXCL_AUD_MENU_ID = "exclAudMenu";

  const segmentationDimensionId = R.keys(
    R.filter(dimension => dimension.type === SEGMENTATION_ID, dimensions)
  )[0];

  const isSegmentedLevel = R.includes(Number(segmentationDimensionId), namingConvention);

  const [nameFields, setNameFields] = useState<Record<string, any>>(
    generateNameFields(namingConvention, allNameFields)
  );
  const [enteredCampaignId, setEnteredCampaignId] = useState("");
  const [showInputCampaignId, setShowInputCampaignId] = useState(false);
  const [showInputInclAudId, setShowInputInclAudId] = useState(false);
  const [showInputExclAudId, setShowInputExclAudId] = useState(false);

  const formStart = metaAdSetForm.start_time
    ? Dfns.format(DATE_FORMAT, Dfns.parseISO(removeIsoTZ(metaAdSetForm.start_time))).split(" ")
    : null;
  const formEnd = metaAdSetForm.end_time
    ? Dfns.format(DATE_FORMAT, Dfns.parseISO(removeIsoTZ(metaAdSetForm.end_time))).split(" ")
    : null;

  const [budgetDates, setBudgetDates] = useState<{ start: string; end: string | null }>(() => ({
    start: formStart ? formStart[0] : DEFAULT_START[0],
    end: formEnd ? formEnd[0] : DEFAULT_END[0],
  }));

  const [budgetTimes, setBudgetTimes] = useState(() => ({
    start: {
      h: formStart ? formStart[1] : DEFAULT_START[1],
      m: formStart ? formStart[2] : DEFAULT_START[2],
      ampm: formStart ? formStart[3] : DEFAULT_START[3],
      tz: formStart ? formStart[4] : DEFAULT_START[4],
    },
    end: {
      h: formEnd ? formEnd[1] : DEFAULT_END[1],
      m: formEnd ? formEnd[2] : DEFAULT_END[2],
      ampm: formEnd ? formEnd[3] : DEFAULT_END[3],
      tz: formEnd ? formEnd[4] : DEFAULT_END[4],
    },
  }));

  const [hasEndDate, setHasEndDate] = useState(() => !R.isNil(metaAdSetForm.end_time));
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [menusState, setMenusState] = useState<Record<string, any>>({
    [CAMPAIGN_MENU_ID]: false,
    [INCL_AUD_MENU_ID]: false,
    [EXCL_AUD_MENU_ID]: false,
  });

  const inheritedFields = filterInheritedFields(allNameFields);

  const toggleMenuState = (menuId, newStatus?) =>
    setMenusState({
      ...menusState,
      [menuId]: !R.isNil(newStatus) ? newStatus : !menusState[menuId],
    });

  const closeMenuState = menuId => toggleMenuState(menuId, false);

  useEffect(() => {
    const handleOutsideClick = event => {
      const closestMenuToggle = event.target.closest(".menuToggle");
      const closestAppliedSelection = event.target.closest(".appliedSelection");

      const idTokens = closestMenuToggle ? closestMenuToggle.id.split("_") : null;
      const menuId: string | null = idTokens ? idTokens[0] : null;
      const nodeType: string | null = idTokens ? idTokens[1] : null;

      if (menuId && nodeType === DROPDOWN_BTN && R.isNil(closestAppliedSelection)) {
        //@ts-ignore
        setMenusState(
          R.fromPairs(
            R.map(([key, value]) => [key, key === menuId ? !value : false], R.toPairs(menusState))
          )
        );
        //@ts-ignore
      } else if (!menuId && R.any(menuState => menuState === true, R.values(menusState))) {
        //@ts-ignore
        setMenusState(R.map(() => false, menusState));
        //@ts-ignore
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [menusState]);

  const setNameField = useCallback(
    (id, value) => {
      let newNameFields = R.clone(nameFields);
      newNameFields[id] = value;
      setNameFields(newNameFields);
    },
    [nameFields]
  );

  const setMetaCampaignForm = useCallback(
    value =>
      setMetaBuyingForm((current: any): typeof metaBuyingFormDefault => {
        return { ...current, campaign: value };
      }),
    [setMetaBuyingForm]
  );

  const setMetaAdSetForm = useCallback(
    value =>
      setMetaBuyingForm((current: any): typeof metaBuyingFormDefault => {
        return { ...current, ad_set: value };
      }),
    [setMetaBuyingForm]
  );

  const updateFormField = useCallback(
    (key: string, value: FormField) =>
      setMetaBuyingForm((current: any): typeof metaBuyingFormDefault => {
        return { ...current, ad_set: { ...current.ad_set, [key]: value } };
      }),
    [setMetaBuyingForm]
  );

  useEffect(() => {
    if (isSegmentedLevel) {
      const filteredSegIds = filterSegmentationIds(nameFields, dimensions, segmentationDimensionId);
      const selectedSegId = nameFields[segmentationDimensionId];
      if (!R.includes(selectedSegId, filteredSegIds) && !R.isEmpty(selectedSegId)) {
        setNameField(segmentationDimensionId, "");
      }
    }
  }, [nameFields, segmentationDimensionId, dimensions, setNameField, isSegmentedLevel]);

  const InputNote = ({ noteText, buttonText, switchInput }): JSX.Element => (
    <p className="metaBuyingInputNote">
      {noteText}&nbsp;
      <button onClick={() => switchInput()} className="metaBuyingInputSwitch">
        {buttonText}
      </button>
    </p>
  );

  const updateAgeRange = (type: "age_min" | "age_max", value: number | string) => {
    let age = Number(value);
    const newMetaAdSetForm = R.clone(metaAdSetForm);
    let newMinAge = R.clone(newMetaAdSetForm.age_min);
    let newMaxAge = R.clone(newMetaAdSetForm.age_max);
    if (type === "age_min") {
      newMinAge = age;
      if (newMinAge > newMaxAge) {
        newMaxAge = age;
      }
    } else if (type === "age_max") {
      newMaxAge = age;
      if (newMaxAge < newMinAge) {
        newMinAge = age;
      }
    }
    newMetaAdSetForm.age_min = newMinAge;
    newMetaAdSetForm.age_max = newMaxAge;
    setMetaAdSetForm(newMetaAdSetForm);
  };

  const MIN_YEAR = new Date().getFullYear();
  const MAX_YEAR = new Date().getFullYear() + 100;
  const TIMEZONE = moment.tz(new Date(), moment.tz.guess(true)).format("z");

  const updateBudgetDate = (date: string, type: string) =>
    setBudgetDates({
      ...budgetDates,
      [type]: date,
    });

  const updateBudgetTime = (time: number | string, unit: string, type: string) =>
    setBudgetTimes({
      ...budgetTimes,
      [type]: {
        ...budgetTimes[type],
        [unit]: time,
      },
    });

  const MetaBuyingDatePicker = ({ dateHandler, timeHandler, type, isDayBlocked }): JSX.Element => (
    <div className="metaBuyingDatePicker">
      <SingleDatePicker
        minYear={MIN_YEAR}
        maxYear={MAX_YEAR}
        date={budgetDates[type]}
        onChange={date => dateHandler(date, type)}
        isDayBlocked={isDayBlocked}
      />
      <div className="timeSelection">
        <DropdownButton
          className={`timeDropdown hour ${budgetTimes[type].h}`}
          title={`${budgetTimes[type].h ? budgetTimes[type].h : "___"}${
            budgetTimes[type].h || budgetTimes[type].m ? ":" : "_"
          }`}
        >
          {R.range(0, 12).map(hourIndex =>
            R.pipe((hour: string) => (
              <Dropdown.Item
                key={hour}
                eventKey={hour}
                onSelect={time => timeHandler(time, "h", type)}
              >
                {hour.padStart(2, "0")}
              </Dropdown.Item>
            ))(`${hourIndex === 0 ? 12 : hourIndex}`)
          )}
        </DropdownButton>

        <DropdownButton
          className={`timeDropdown minute ${budgetTimes[type].m}`}
          title={budgetTimes[type].m ? budgetTimes[type].m.padStart(2, "0") : "___"}
        >
          {R.range(0, 60).map(minIndex =>
            R.pipe((min: string) => (
              <Dropdown.Item
                key={min}
                eventKey={min}
                onSelect={time => timeHandler(time, "m", type)}
              >
                {min.padStart(2, "0")}
              </Dropdown.Item>
            ))(minIndex.toString())
          )}
        </DropdownButton>
        <DropdownButton
          className={`timeDropdown ampm ${budgetTimes[type].h || budgetTimes[type].m}`}
          title={budgetTimes[type].ampm}
        >
          {["AM", "PM"].map(ampm => (
            <Dropdown.Item
              key={ampm}
              eventKey={ampm}
              onSelect={time => timeHandler(time, "ampm", type)}
            >
              {ampm}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
      <div className="timezone">{TIMEZONE}</div>
    </div>
  );

  const MultiInputSubmit = ({ submit, remove, submissions }): JSX.Element => {
    const [value, setValue] = useState("");

    return (
      <div className="metaBuyingSubmitInputWrapper">
        <div className="metaBuyingSubmissions">
          {submissions.map((submission, index) => (
            <div className="appliedSelection" onClick={() => remove(value, index, submissions)}>
              {submission}
            </div>
          ))}
        </div>
        <Form.Control
          style={{
            paddingTop: `calc(${submissions.length * 1.375}rem + ${
              (submissions.length > 1 ? (submissions.length - 1) * 6 : 0) +
              (submissions.length === 0 ? 10 : 20)
            }px)`,
          }}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <Button
          className="metaBuyingSubmitButton"
          type={ButtonType.FILLED}
          variant={ButtonFrameworkVariant.LEADING_ICON}
          icon={<HiPlus />}
          onClick={() => submit(value)}
          size="sm"
          disabled={R.isEmpty(value)}
        >
          Submit
        </Button>
      </div>
    );
  };

  const optimizationGoal = useMemo(
    () =>
      R.find(
        ({ value }) => R.equals(value, metaAdSetForm.optimization_goal),
        CONVERSION_GOAL_OPTIONS
      ),
    [metaAdSetForm.optimization_goal]
  );

  const bidStrategies = useMemo(() => {
    return optimizationGoal?.validOptions.bidStrategies;
  }, [optimizationGoal]);

  const includeMinRoasOption = R.includes(LOWEST_COST_WITH_MIN_ROAS, bidStrategies || []);

  const showConversionEventOption =
    R.includes(metaCampaignForm.objective, [OUTCOME_SALES]) &&
    (metaAdSetForm.optimization_goal === OFFSITE_CONVERSIONS ||
      metaAdSetForm.optimization_goal === VALUE);

  const validConversionGoalOptions = useMemo(
    () =>
      CONVERSION_GOAL_OPTIONS.filter(option => {
        const { validOptions } = option;
        const includesCampaignObjective = R.includes(
          metaCampaignForm.objective,
          validOptions.objectives
        );
        const validForCampaignBidStrategy =
          R.isNil(metaCampaignForm.bid_strategy) ||
          R.includes(metaCampaignForm.bid_strategy, validOptions.bidStrategies);

        return includesCampaignObjective && validForCampaignBidStrategy;
      }),
    [metaCampaignForm]
  );

  const limitAudience = useMemo(
    () =>
      R.any(
        (category: SpecialAdCategory) => SPECIAL_AD_CATEGORIES[category].limitAudienceTargeting,
        metaCampaignForm.special_ad_categories
      ),
    [metaCampaignForm]
  );

  useEffect(() => {
    let newMetaAdSetForm = R.clone(metaAdSetForm);
    const newAdSetName = namingConvention.map(dimensionId => nameFields[dimensionId]).join("_");

    const { start, end } = budgetTimes;
    const startHr = Number(start.h);
    const endHr = Number(end.h);

    const fullStartTime = `${budgetDates.start}T${`${
      start.ampm === "PM" && startHr !== 12 ? startHr + 12 : startHr
    }`.padStart(2, "0")}:${start.m.padStart(2, "0")}:00${start.tz}`;

    const fullEndTime = `${budgetDates.end}T${`${
      end.ampm === "PM" && endHr !== 12 ? endHr + 12 : endHr
    }`.padStart(2, "0")}:${end.m.padStart(2, "0")}:00${end.tz}`;

    const isoStart = Dfns.parseISO(removeIsoTZ(fullStartTime));
    const isoEnd = Dfns.parseISO(removeIsoTZ(fullEndTime));

    if (isoEnd && (Dfns.isAfter(isoEnd, isoStart) || R.equals(isoStart, isoEnd))) {
      setBudgetDates({
        ...budgetDates,
        end: Dfns.format(DATE_FORMAT.split(" ")[0], Dfns.addDays(1, isoStart)),
      });
    }

    if (!R.equals(fullStartTime, metaAdSetForm.start_time)) {
      newMetaAdSetForm.start_time = fullStartTime;
    }
    if (!R.equals(fullEndTime, metaAdSetForm.end_time)) {
      newMetaAdSetForm.end_time = fullEndTime;
    }

    if (!hasEndDate) {
      newMetaAdSetForm.end_time = null;
    }

    if (!R.equals(newAdSetName, metaAdSetForm.adset_name)) {
      newMetaAdSetForm.adset_name = newAdSetName;
    }
    if (
      metaCampaignForm.bid_strategy &&
      metaCampaignForm.bid_strategy !== metaAdSetForm.bid_strategy
    ) {
      newMetaAdSetForm = {
        ...newMetaAdSetForm,
        optimization_goal:
          metaCampaignForm.bid_strategy === LOWEST_COST_WITH_MIN_ROAS ? VALUE : null,
        conversion_event: null,
        bid_amount: null,
        bid_constraints: {},
        bid_strategy: !R.isNil(metaCampaignForm.bid_strategy)
          ? metaCampaignForm.bid_strategy
          : LOWEST_COST_WITHOUT_CAP,
      };
    }

    if (metaAdSetForm.optimization_goal === VALUE && metaAdSetForm.conversion_event !== PURCHASE) {
      newMetaAdSetForm.conversion_event = PURCHASE;
    }

    if (
      R.includes(metaCampaignForm.budget_type, [CAMPAIGN_DAILY_BUDGET, CAMPAIGN_LIFETIME_BUDGET])
    ) {
      newMetaAdSetForm.budget = null;
      newMetaAdSetForm.budget_type = null;
    } else if (
      R.includes(metaAdSetForm.budget_type, [AD_SET_DAILY_BUDGET, AD_SET_LIFETIME_BUDGET]) &&
      R.isNil(metaAdSetForm.budget)
    ) {
      newMetaAdSetForm.budget = 2000;
    } else if (
      metaAdSetForm.budget_type !== AD_SET_DAILY_BUDGET &&
      metaAdSetForm.budget_type !== AD_SET_LIFETIME_BUDGET &&
      !R.isNil(metaAdSetForm.budget)
    ) {
      newMetaAdSetForm.budget = null;
    }

    const { click_through, view_through } = getAttributionOptions(
      metaCampaignForm.objective,
      metaAdSetForm.optimization_goal
    );

    if (!R.includes(metaAdSetForm.click_attr_window, click_through)) {
      newMetaAdSetForm.click_attr_window = click_through[0];
    }
    if (!R.includes(metaAdSetForm.view_attr_window, view_through)) {
      newMetaAdSetForm.view_attr_window = view_through[0];
    }

    if (!R.equals(newMetaAdSetForm, metaAdSetForm)) {
      setMetaAdSetForm(newMetaAdSetForm);
    }
  }, [
    hasEndDate,
    budgetTimes,
    budgetDates,
    nameFields,
    updateFormField,
    namingConvention,
    metaAdSetForm,
    metaCampaignForm,
    setMetaAdSetForm,
  ]);

  const AttributionSpec = (): JSX.Element | null => {
    if (
      R.includes(metaCampaignForm.objective, [OUTCOME_SALES]) &&
      !R.isNil(metaAdSetForm.optimization_goal)
    ) {
      const { click_through, view_through } = getAttributionOptions(
        metaCampaignForm.objective,
        metaAdSetForm.optimization_goal
      );

      return (
        <div className="metaBuyingFormSection">
          <h3>Ad Set Attribution Specs</h3>
          <Form.Group>
            <Form.Label>Click Through Attribution Window</Form.Label>
            {click_through.map(numDays => (
              <Form.Check
                key={numDays}
                type="radio"
                label={numDays}
                checked={metaAdSetForm.click_attr_window === numDays}
                id={`click-window-${numDays}`}
                onChange={() => updateFormField("click_attr_window", numDays)}
              />
            ))}
          </Form.Group>
          <Form.Group>
            <Form.Label>View Through Attribution Window</Form.Label>
            {view_through.map(numDays => (
              <Form.Check
                key={numDays}
                type="radio"
                label={numDays}
                checked={metaAdSetForm.view_attr_window === numDays}
                id={`view-window-${numDays}`}
                onChange={() => updateFormField("view_attr_window", numDays)}
              />
            ))}
          </Form.Group>
        </div>
      );
    }
    return null;
  };

  const applyCampaign = useCallback(
    campaign => {
      try {
        if (
          !validateName(
            campaign.campaign_name,
            CAMPAIGN,
            namingConventions,
            dimensions,
            client,
            business_manager
          )
        ) {
          throw new Error(
            `Campaign name '${campaign.campaign_name}' does not follow ${client}'s naming convention`
          );
        }

        let newMetaCampaignForm = R.clone(metaCampaignFormDefault);

        newMetaCampaignForm.campaign_name = campaign.campaign_name || "";
        newMetaCampaignForm.status = campaign.adsmanager_status || STATUS_NONE;
        newMetaCampaignForm.bid_strategy = campaign.bid_strategy || null;
        newMetaCampaignForm.objective = campaign.objective || OUTCOME_SALES;
        newMetaCampaignForm.special_ad_categories = campaign.special_ad_categories || [];

        if (campaign.daily_budget) {
          newMetaCampaignForm.budget = Number(campaign.daily_budget);
          newMetaCampaignForm.budget_type = CAMPAIGN_DAILY_BUDGET;
        } else if (campaign.lifetime_budget) {
          newMetaCampaignForm.budget = Number(campaign.lifetime_budget);
          newMetaCampaignForm.budget_type = CAMPAIGN_LIFETIME_BUDGET;
        }

        const parentNameElements = campaign.campaign_name.split("_");
        const thisNamingConvention = namingConventions[AD_SET];
        const parentNamingConvention = namingConventions[CAMPAIGN];

        let newNameFields = R.clone(nameFields);
        thisNamingConvention.forEach(thisDimId => {
          const parentDimIndex = R.findIndex(
            parentDimId => parentDimId === thisDimId,
            parentNamingConvention
          );

          if (parentDimIndex > -1) {
            newNameFields[thisDimId] = parentNameElements[parentDimIndex];
          }
        });
        setNameFields(newNameFields);

        setMetaCampaignForm(newMetaCampaignForm);
        updateFormField("campaign_id", campaign.id);
      } catch (e) {
        setError({
          title: "Invalid Campaign",
          message: e.message,
        });
      }
    },
    [
      nameFields,
      namingConventions,
      setMetaCampaignForm,
      business_manager,
      client,
      dimensions,
      setError,
      updateFormField,
    ]
  );

  const removeCampaign = () => {
    setMetaCampaignForm(metaCampaignFormDefault);
    setNameFields(
      R.fromPairs(
        R.map(
          ([key, value]) => [key, R.includes(Number(key), inheritedFields) ? "" : value],
          R.toPairs(nameFields)
        )
      )
    );
    setEnteredCampaignId("");
    updateFormField("campaign_id", null);
  };

  const submitCampaignId = useCallback(
    async id => {
      setCampaignLoading(true);

      try {
        let res = await MetaLambdaFetch("/getCampaignInfo", {
          params: {
            account_id,
            campaign_id: id,
            businessManager: business_manager,
            user: email,
          },
        });
        let { data } = await awaitJSON(res);

        if (R.isNil(data) || R.isEmpty(data)) {
          setError({
            title: "Failed to Fetch Campaign",
            message: `No campaigns found with ID ${id} in account ${account_id}`,
          });
        } else {
          applyCampaign(data);
        }
      } catch (e) {
        setError({ message: e.message, reportError: e });
      } finally {
        setCampaignLoading(false);
      }
    },
    [account_id, applyCampaign, business_manager, email, setError]
  );

  const switchInput = () => {
    removeCampaign();
    setShowInputCampaignId(!showInputCampaignId);
  };

  const selectOptimizationGoal = value =>
    setMetaAdSetForm({
      ...metaAdSetForm,
      optimization_goal: value,
      bid_amount: null,
      bid_constraints: {},
      bid_strategy: !R.isNil(metaCampaignForm.bid_strategy)
        ? metaCampaignForm.bid_strategy
        : LOWEST_COST_WITHOUT_CAP,
      pixel: R.includes(value, [OFFSITE_CONVERSIONS, VALUE]) ? metaAdSetForm.pixel : null,
      conversion_event: R.includes(value, [OFFSITE_CONVERSIONS, VALUE])
        ? metaAdSetForm.conversion_event
        : null,
    });

  const updateBidValues = value => {
    let newVal: number | null = parseFloat(value.replace(/[^0-9]/g, ""));
    if (isNaN(newVal)) {
      newVal = 0;
    }
    if (includeMinRoasOption) {
      newVal = newVal === 0 ? 0 : Math.min(1000000, Math.max(1, newVal)) * 10;
      setMetaAdSetForm({
        ...metaAdSetForm,
        bid_amount: null,
        bid_constraints: R.isNil(newVal) || newVal === 0 ? {} : { roas_average_floor: newVal },
        bid_strategy:
          R.isNil(newVal) || newVal === 0 ? LOWEST_COST_WITHOUT_CAP : LOWEST_COST_WITH_MIN_ROAS,
      });
    } else {
      newVal = R.isNil(newVal) || newVal === 0 ? null : newVal;
      let { bid_strategy } = metaAdSetForm;
      if (!newVal && !metaCampaignForm.bid_strategy) {
        bid_strategy = LOWEST_COST_WITHOUT_CAP;
      } else if (
        newVal &&
        (!metaAdSetForm.bid_strategy || metaAdSetForm.bid_strategy === LOWEST_COST_WITHOUT_CAP)
      ) {
        bid_strategy =
          metaAdSetForm.optimization_goal === IMPRESSIONS ? LOWEST_COST_WITH_BID_CAP : COST_CAP;
      }
      setMetaAdSetForm({
        ...metaAdSetForm,
        bid_amount: newVal,
        bid_constraints: {},
        bid_strategy,
      });
    }
  };

  const pixelMap = mapRowsByProp("pixel_id", pixels)[metaAdSetForm.pixel];

  return (
    <>
      <MetaBuyingTabs
        tabIndex={tabIndex}
        creationTabs={creationTabs}
        validate={index => {
          if (index <= tabIndex) {
            saveNameFields(nameFields);
            setTabIndex(index);
          } else {
            validateForms(metaBuyingForm, () => setTabIndex(index), nameFields, TAB_AD_SET);
          }
        }}
      />
      <div className="metaBuyingCreate">
        <div className="metaBuyingContainer">
          <div className="metaBuyingCard">
            <div className="metaBuyingHeader">
              <h2>Ad&nbsp;Set</h2>
              <div className="metaBuyingNameWrapper">
                Name:
                <MetaBuyingNamePreview
                  dimensions={dimensions}
                  nameFields={nameFields}
                  namingConvention={namingConvention}
                  inheritedFields={inheritedFields}
                />
              </div>
            </div>

            <div className="metaBuyingFormBody">
              <div className="metaBuyingFormSection">
                <h3>Ad Set Name Construction</h3>
                <MetaBuyingNameFields
                  namingConvention={namingConvention}
                  dimensions={dimensions}
                  nameFields={nameFields}
                  segmentationDimensionId={segmentationDimensionId}
                  client={client}
                  agency={business_manager}
                  setNameField={setNameField}
                  inheritedFields={inheritedFields}
                  granularity={granularity}
                />
              </div>

              {!R.includes(TAB_CAMPAIGN, creationTabs) && (
                <div className="metaBuyingFormSection">
                  <h3>Campaign</h3>
                  <Form.Group>
                    <Form.Label>Campaign</Form.Label>
                    {showInputCampaignId ? (
                      <>
                        <InputNote
                          noteText="Can’t input Campaign ID?"
                          buttonText="Select from dropdown instead"
                          switchInput={() => switchInput()}
                        />
                        <div className="metaBuyingSubmitInputWrapper">
                          <div className="metaBuyingSubmission">
                            {metaAdSetForm.campaign_id && (
                              <div className="appliedSelection" onClick={removeCampaign}>
                                {metaAdSetForm.campaign_id}
                              </div>
                            )}
                          </div>
                          <Form.Control
                            style={{ paddingTop: 10 }}
                            value={enteredCampaignId}
                            onChange={e => setEnteredCampaignId(e.target.value)}
                            disabled={metaAdSetForm.campaign_id || campaignLoading}
                          />
                          {R.isNil(metaAdSetForm.campaign_id) && (
                            <Button
                              className="metaBuyingSubmitButton"
                              type={ButtonType.FILLED}
                              variant={ButtonFrameworkVariant.LEADING_ICON}
                              icon={<HiPlus />}
                              onClick={() => submitCampaignId(enteredCampaignId)}
                              size="sm"
                              disabled={R.isEmpty(enteredCampaignId) || campaignLoading}
                            >
                              Submit
                            </Button>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <InputNote
                          noteText="Don’t see your campaign?"
                          buttonText="Enter Campaign ID instead"
                          switchInput={() => switchInput()}
                        />
                        <SingleSelectMenu
                          placeholder="Select Campaign"
                          menuId={CAMPAIGN_MENU_ID}
                          data={campaigns}
                          nameProp="campaign_name"
                          closeMenu={() => closeMenuState(CAMPAIGN_MENU_ID)}
                          showMenu={menusState[CAMPAIGN_MENU_ID]}
                          applySelection={id => applyCampaign(mapRowsByProp("id", campaigns)[id])}
                          removeSelection={removeCampaign}
                          appliedSelection={metaAdSetForm.campaign_id}
                        />
                      </>
                    )}
                  </Form.Group>
                </div>
              )}

              <div className="metaBuyingFormSection">
                <h3>Conversion Options</h3>
                {R.includes(metaCampaignForm.objective, [OUTCOME_SALES]) && (
                  <Form.Group>
                    <Form.Label>Optimization Goal</Form.Label>
                    <DropdownButton
                      title={
                        //@ts-ignore
                        mapLabelsByValues(CONVERSION_GOAL_OPTIONS)[
                          metaAdSetForm.optimization_goal
                        ] || ""
                        //@ts-ignore
                      }
                      onSelect={value => selectOptimizationGoal(value)}
                      disabled={metaCampaignForm.bid_strategy === LOWEST_COST_WITH_MIN_ROAS}
                    >
                      {validConversionGoalOptions.map(({ label, value }) => (
                        <Dropdown.Item key={value} eventKey={value}>
                          {label}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </Form.Group>
                )}
                {R.includes(metaAdSetForm.optimization_goal, [OFFSITE_CONVERSIONS, VALUE]) && (
                  <Form.Group>
                    <Form.Label>Pixel</Form.Label>
                    <DropdownButton
                      title={pixelMap ? pixelMap.name : ""}
                      onSelect={value => updateFormField("pixel", value)}
                    >
                      {pixels.map(({ name, pixel_id }) => (
                        <Dropdown.Item key={pixel_id} eventKey={pixel_id}>
                          {name}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </Form.Group>
                )}

                {showConversionEventOption && (
                  <Form.Group>
                    <Form.Label>Conversion Event</Form.Label>
                    <DropdownButton
                      title={
                        //@ts-ignore
                        mapLabelsByValues(CONVERSION_EVENT_OPTIONS)[
                          metaAdSetForm.conversion_event
                        ] || ""
                        //@ts-ignore
                      }
                      onSelect={value => updateFormField("conversion_event", value)}
                      disabled={metaAdSetForm.optimization_goal === VALUE}
                    >
                      {CONVERSION_EVENT_OPTIONS.map(({ label, value, validOptions }) =>
                        R.includes(metaCampaignForm.objective, validOptions.objectives) ? (
                          <Dropdown.Item key={value} eventKey={value}>
                            {label}
                          </Dropdown.Item>
                        ) : null
                      )}
                    </DropdownButton>
                  </Form.Group>
                )}

                {!R.isNil(bidStrategies) && (
                  <>
                    <Form.Group>
                      <Form.Label>
                        {includeMinRoasOption
                          ? "ROAS Goal"
                          : metaAdSetForm.bid_strategy === LOWEST_COST_WITH_BID_CAP ||
                            metaAdSetForm.optimization_goal === IMPRESSIONS
                          ? BID_STRATEGIES[LOWEST_COST_WITH_BID_CAP].inputLabel
                          : BID_STRATEGIES[COST_CAP].inputLabel}
                      </Form.Label>
                      <Form.Control
                        className={
                          metaCampaignForm.bid_strategy === LOWEST_COST_WITHOUT_CAP
                            ? "disabled"
                            : ""
                        }
                        value={
                          includeMinRoasOption &&
                          !R.isNil(metaAdSetForm.bid_constraints.roas_average_floor)
                            ? (metaAdSetForm.bid_constraints.roas_average_floor / 10000).toFixed(3)
                            : !R.isNil(metaAdSetForm.bid_amount)
                            ? `$${(metaAdSetForm.bid_amount / 100).toFixed(2)}`
                            : ""
                        }
                        onChange={e => updateBidValues(e.target.value)}
                        disabled={metaCampaignForm.bid_strategy === LOWEST_COST_WITHOUT_CAP}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Bid Strategy</Form.Label>
                      <DropdownButton
                        disabled={
                          metaAdSetForm.bid_strategy === LOWEST_COST_WITHOUT_CAP ||
                          metaAdSetForm.bid_strategy === LOWEST_COST_WITH_MIN_ROAS ||
                          metaAdSetForm.optimization_goal === IMPRESSIONS ||
                          !R.isNil(metaCampaignForm.bid_strategy)
                        }
                        title={BID_STRATEGIES[metaAdSetForm.bid_strategy].label}
                        onSelect={value => updateFormField("bid_strategy", value)}
                      >
                        {R.includes(LOWEST_COST_WITH_BID_CAP, bidStrategies) && (
                          <Dropdown.Item
                            key={LOWEST_COST_WITH_BID_CAP}
                            eventKey={LOWEST_COST_WITH_BID_CAP}
                          >
                            {BID_STRATEGIES[LOWEST_COST_WITH_BID_CAP].label}
                          </Dropdown.Item>
                        )}
                        {R.includes(COST_CAP, bidStrategies) && (
                          <Dropdown.Item key={COST_CAP} eventKey={COST_CAP}>
                            {BID_STRATEGIES[COST_CAP].label}
                          </Dropdown.Item>
                        )}
                      </DropdownButton>
                    </Form.Group>
                  </>
                )}
              </div>

              <AttributionSpec />

              <div className="metaBuyingFormSection">
                <h3>Budget and Schedule</h3>
                <Form.Group className="metaBuyingScheduleWrapper">
                  <Form.Label>Start Date and Time</Form.Label>
                  <MetaBuyingDatePicker
                    dateHandler={updateBudgetDate}
                    timeHandler={updateBudgetTime}
                    type="start"
                    isDayBlocked={day => Dfns.isAfter(Dfns.parseISO(day), Dfns.parseISO(TODAY))}
                  />
                  <Form.Label>End Date and Time</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Set an end date"
                    checked={hasEndDate}
                    onChange={() => setHasEndDate(!hasEndDate)}
                  />{" "}
                  {hasEndDate && (
                    <MetaBuyingDatePicker
                      dateHandler={updateBudgetDate}
                      timeHandler={updateBudgetTime}
                      type="end"
                      isDayBlocked={day =>
                        Dfns.isAfter(Dfns.parseISO(day), Dfns.parseISO(budgetDates.start))
                      }
                    />
                  )}
                </Form.Group>
                {metaCampaignForm.budget_type !== CAMPAIGN_DAILY_BUDGET &&
                  metaCampaignForm.budget_type !== CAMPAIGN_LIFETIME_BUDGET && (
                    <>
                      <Form.Group>
                        <Form.Label>Budget Type</Form.Label>
                        {BUDGET_TYPES[AD_SET].map(({ label, value }) => (
                          <Form.Check
                            key={value}
                            type="radio"
                            label={label}
                            checked={metaAdSetForm.budget_type === value}
                            id={value}
                            onChange={() => updateFormField("budget_type", value)}
                          />
                        ))}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Budget</Form.Label>
                        <Form.Control
                          value={formatBudget(metaAdSetForm.budget)}
                          onChange={e => updateBudget(e.target.value, updateFormField)}
                        />
                      </Form.Group>
                    </>
                  )}
              </div>

              <div className="metaBuyingFormSection">
                <h3>Ad Set Targeting</h3>
                <Form.Group>
                  <Form.Label>Include Custom Audiences</Form.Label>
                  {!showInputInclAudId && (
                    <InputNote
                      noteText="Don’t see your audience?"
                      buttonText="Enter Audience ID"
                      switchInput={() => setShowInputInclAudId(true)}
                    />
                  )}
                  <MultiSelectMenu
                    placeholder="Select Custom Audience"
                    menuId={INCL_AUD_MENU_ID}
                    data={customAudiences}
                    closeMenu={() => closeMenuState(INCL_AUD_MENU_ID)}
                    showMenu={menusState[INCL_AUD_MENU_ID]}
                    applySelections={value => updateFormField("included_audiences_selected", value)}
                    appliedSelections={metaAdSetForm.included_audiences_selected}
                    subTypeMap={AUDIENCE_SUBTYPE_MAP}
                    idProp="custom_audience_id"
                  />
                  {showInputInclAudId && (
                    <MultiInputSubmit
                      submit={value => {
                        updateFormField("included_audiences_entered", [
                          ...metaAdSetForm.included_audiences_entered,
                          value,
                        ]);
                      }}
                      remove={(_, index) =>
                        updateFormField(
                          "included_audiences_entered",
                          R.remove(index, 1, metaAdSetForm.included_audiences_entered) as string[]
                        )
                      }
                      submissions={metaAdSetForm.included_audiences_entered}
                    />
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Exclude Custom Audiences</Form.Label>
                  {!showInputExclAudId && (
                    <InputNote
                      noteText="Don’t see your audience?"
                      buttonText="Enter Audience ID"
                      switchInput={() => setShowInputExclAudId(true)}
                    />
                  )}
                  <MultiSelectMenu
                    placeholder="Select Custom Audience"
                    menuId={EXCL_AUD_MENU_ID}
                    data={customAudiences}
                    closeMenu={() => closeMenuState(EXCL_AUD_MENU_ID)}
                    showMenu={menusState[EXCL_AUD_MENU_ID]}
                    applySelections={value => updateFormField("excluded_audiences_selected", value)}
                    appliedSelections={metaAdSetForm.excluded_audiences_selected}
                    subTypeMap={AUDIENCE_SUBTYPE_MAP}
                    idProp="custom_audience_id"
                  />
                  {showInputExclAudId && (
                    <MultiInputSubmit
                      submit={value => {
                        updateFormField("excluded_audiences_entered", [
                          ...metaAdSetForm.excluded_audiences_entered,
                          value,
                        ]);
                      }}
                      remove={(_, index) =>
                        updateFormField(
                          "excluded_audiences_entered",
                          R.remove(index, 1, metaAdSetForm.excluded_audiences_entered) as string[]
                        )
                      }
                      submissions={metaAdSetForm.excluded_audiences_entered}
                    />
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Gender</Form.Label>
                  <DropdownButton
                    title={metaAdSetForm.genders}
                    onSelect={value => updateFormField("genders", value)}
                    disabled={limitAudience}
                  >
                    {GENDER_OPTIONS.map(value => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {value}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Minimum Age</Form.Label>
                  <DropdownButton
                    title={formatAge(metaAdSetForm.age_min)}
                    onSelect={value => updateAgeRange("age_min", value || 18)}
                    disabled={limitAudience}
                  >
                    {AGE_OPTIONS.map(age => (
                      <Dropdown.Item key={age} eventKey={age.toString()}>
                        {formatAge(age)}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Maximum Age</Form.Label>
                  <DropdownButton
                    title={formatAge(metaAdSetForm.age_max)}
                    onSelect={value => updateAgeRange("age_max", value || 65)}
                    disabled={limitAudience}
                  >
                    {AGE_OPTIONS.map(age => (
                      <Dropdown.Item key={age} eventKey={age.toString()}>
                        {formatAge(age)}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
              </div>

              <MetaBuyingPlacements />

              <MetaBuyingFooterControls
                nextTabName={creationTabs[tabIndex + 1]}
                handleReviewClick={() => validateForms(metaBuyingForm, showReviewModal, nameFields)}
                handleNextClick={
                  creationTabs[tabIndex + 1]
                    ? () =>
                        validateForms(
                          metaBuyingForm,
                          () => setTabIndex(tabIndex + 1),
                          nameFields,
                          TAB_AD_SET
                        )
                    : undefined
                }
              />
            </div>
          </div>

          <MetaBuyingOptionsNoteCard
            header={OPTIONS_NOTE_CARD_HEADERS[AD_SET]}
            text={OPTIONS_NOTE_CARD_TEXTS[AD_SET]}
            options={OPTIONS_NOTE_CARD_OPTIONS[AD_SET]}
          />
        </div>
      </div>
    </>
  );
};

export default MetaBuyingAdSet;
