import "./ModalEditTableForBudgets.scss";
import { Alert, Button, Form, Modal, Tooltip } from "react-bootstrap";
import {
  Button as ButtonIcon,
  CheckBox,
  Header,
  NumberFormatter,
  OverlayTrigger,
  SingleDatePicker,
  BPMFileInput,
  CellRenderer,
  InfoTooltip,
  ButtonType,
  ButtonFrameworkVariant,
  Spinner,
} from "../../Components";
import {
  MdAdd,
  MdArrowDownward,
  MdArrowUpward,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdDelete,
  MdError,
  MdOutlineAvTimer,
  MdOutlineFileDownload,
  MdOutlineUploadFile,
  MdSave,
} from "react-icons/md";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import * as uuid from "uuid";
import cn from "classnames";
import CreatableSelect from "react-select/creatable";
import React, { Dispatch, useCallback, useLayoutEffect, useState, useMemo } from "react";
import { S3Put } from "../../utils/fetch-utils";
import TargetingCheckBox from "../../MasterTargeting/TargetingCheckBox";
import { BudgetIntakeToolTable } from "../BudgetIntakeToolTable";
import { StateSetter } from "../../utils/types";
import { exportToExcelWithDropDown } from "../excelUtils";
import ImportModal from "./ImportModal";

const ROW_HEIGHT = 25;
const PRETTY_DATE_FORMAT = "yyyy-MM-dd hh:mma";

const renderForType = (type: string, value: string | boolean | JSX.Element, isMulti: boolean) => {
  switch (type) {
    case "percent":
      return <NumberFormatter value={parseFloat(value as string)} type={"%"} />;
    case "currency":
      return <NumberFormatter value={parseFloat(value as string)} type={"$"} />;
    case "decimal":
    case "real":
      return <NumberFormatter value={parseFloat(value as string)} type={"#"} />;
    case "timestamp":
      return Dfns.format(PRETTY_DATE_FORMAT)(Dfns.parseISO(value as string));
    case "checkbox":
      return <CheckBox checked={value as boolean} />;
    case "file":
      return value ? value : "No file";
    default:
      return value;
  }
};

const SelectorElement = ({ header, row, value, setValue, selectorOptions, isValidNewOption }) => {
  const [input, setInput] = useState();

  const options = useMemo(() => {
    if (!header || !header.options || !selectorOptions[header.options]) {
      return [];
    }

    if (header.options === "networkRotation") {
      if (row.network) {
        return selectorOptions.networkRotation[row.network];
      } else {
        return [];
      }
    } else if (header.options === "networkRotationWithoutEach") {
      if (row.network) {
        return selectorOptions.networkRotationWithoutEach[row.network];
      } else {
        return [];
      }
    } else if (header.options === "companiesConstantOnSet") {
      if (R.isNil(row.company)) {
        return selectorOptions.companiesConstantOnSet;
      } else {
        return [{ label: row.company, value: row.company }];
      }
    }
    return selectorOptions[header.options];
  }, [header, row, selectorOptions]);

  const displayValue = useMemo(() => {
    if (header.isMulti) {
      return R.map(elem => ({ value: elem, label: elem }), R.defaultTo([], value));
    } else {
      return {
        value,
        label: value,
      };
    }
  }, [value, header]);

  return (
    <CreatableSelect
      isClearable={R.isNil(header.isClearable) || header.isClearable}
      isMulti={header.isMulti}
      isValidNewOption={isValidNewOption ?? (() => false)}
      inputValue={input}
      onInputChange={setInput}
      value={displayValue}
      options={options}
      onChange={value => {
        if (header.isMulti) {
          setValue(value ? R.filter(elem => !R.isNil(elem), R.map(R.prop("value"), value)) : []);
        } else {
          setValue(R.prop("value", value) ?? "");
        }
      }}
    />
  );
};

export interface SelectorOption {
  label: string | number;
  value: string | number;
}

export interface TableDataType {
  selected?: boolean;
  index?: number;
  [key: string]: any;
}

export interface FileToUpload {
  fileName: string;
  file: File;
}

interface ModalRowEditorProps<T> {
  checkIsValid: ((data: T) => Promise<boolean>) | ((data: T) => boolean) | null;
  customFooterButtons: CustomFooterButton<T>[];
  deletedRows: T[];
  enableDelete: boolean;
  headers: Header[];
  invalidText: string;
  footerText: string;
  isAddingNewRow: boolean;
  name: string | JSX.Element;
  onHide: (args?) => void;
  rowData: T;
  selectorOptions: Record<string, SelectorOption[]>;
  setDeletedRows: Dispatch<T[]>;
  setIsAddingNewRow: Dispatch<boolean>;
  setRowData: (rowData: T, oldIndex: number, newIndex: number) => void;
  className: string;
  checkShowFooter: ((data: T) => boolean) | null;
}

const ModalRowEditor = <T extends TableDataType>(props: ModalRowEditorProps<T>): JSX.Element => {
  const {
    checkIsValid = null,
    checkShowFooter = null,
    customFooterButtons = [],
    deletedRows,
    enableDelete = true,
    headers,
    invalidText = "",
    footerText = "",
    isAddingNewRow,
    name,
    onHide,
    rowData,
    selectorOptions,
    setDeletedRows = () => [],
    setIsAddingNewRow,
    setRowData,
    className,
  } = props;

  const [editedData, setEditedData] = useState<T>({} as T);
  const [shouldShowInvalidText, setShouldShowInvalidText] = useState<boolean>(false);
  const [pdfToUpload, setPdfToUpload] = useState<FileToUpload>();

  const data: T = useMemo(() => R.mergeRight(rowData, editedData), [editedData, rowData]);

  const shouldShowFooterText = useMemo(() => {
    if (checkShowFooter) {
      return checkShowFooter(rowData);
    }
    return true;
  }, [rowData, checkShowFooter]);

  const onDelete = useCallback(() => {
    if (!R.isNil(rowData.index)) {
      deletedRows.push(rowData);
      setDeletedRows && setDeletedRows(deletedRows);
      setRowData(data, rowData.index, -1);
      setIsAddingNewRow(false);
      setShouldShowInvalidText(false);
    }
  }, [data, deletedRows, rowData, setDeletedRows, setIsAddingNewRow, setRowData]);

  const onCancel = useCallback(() => {
    if (isAddingNewRow) {
      onDelete();
    }
    onHide();
    setShouldShowInvalidText(false);
  }, [isAddingNewRow, onDelete, onHide]);

  const onSave = useCallback(async () => {
    let isValid = true;
    if (checkIsValid) {
      isValid = await checkIsValid(data);
    }
    if (isValid && !R.isNil(rowData.index) && !R.isNil(data.index)) {
      setRowData(data, rowData.index, data.index);
      setIsAddingNewRow(false);
      setShouldShowInvalidText(false);
    } else {
      setShouldShowInvalidText(true);
    }
    if (pdfToUpload) {
      const reader = new FileReader();
      reader.onload = e => {
        let data = e?.target?.result as string;
        const splitString = data.split(",");
        data = splitString[1];

        S3Put("bpm-cdn", pdfToUpload.fileName, data, {
          bufferEncoding: "base64",
          contentType: "application/pdf",
        });
      };
      reader.readAsDataURL(pdfToUpload.file);
    }
  }, [checkIsValid, data, rowData.index, setIsAddingNewRow, setRowData, pdfToUpload]);

  const invalidSet = useMemo(
    () =>
      R.reduce(
        (invalidSet, header) =>
          header.isInvalid && header.isInvalid(data[R.defaultTo("", header.field)])
            ? R.assoc(R.defaultTo("", header.field), true, invalidSet)
            : invalidSet,
        {},
        headers
      ),
    [data, headers]
  );

  const makeInputElement = useCallback(
    header => {
      let headerType = header.type;
      if (header.rendererType) {
        headerType = header.rendererType(data);
      }
      if (header.uneditable) {
        return;
      }
      if (headerType === "select") {
        return (
          <SelectorElement
            header={header}
            value={data[header.field] ?? header.default}
            row={data}
            key={header.field}
            isValidNewOption={header.isValidNewOption}
            setValue={newValue => {
              setEditedData({ ...editedData, [header.field]: newValue });
            }}
            selectorOptions={selectorOptions}
          />
        );
      } else if (headerType === "currency" || headerType === "percent") {
        return (
          <Form.Control
            isInvalid={invalidSet[header.field]}
            value={
              !R.isNil(data[header.field])
                ? parseFloat(data[header.field]).toLocaleString("en-US", {
                    style: headerType,
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : ""
            }
            onChange={event => {
              let newVal: number = parseFloat(event.target.value.replace(/[^0-9]/g, ""));
              if (headerType === "percent" && !isNaN(newVal)) {
                newVal /= 100;
                newVal = Math.min(1, Math.max(0, newVal));
              }
              setEditedData({
                ...editedData,
                [header.field]: isNaN(newVal) ? null : newVal,
              });
            }}
          />
        );
      } else if (headerType === "real") {
        return (
          <Form.Control
            isInvalid={invalidSet[header.field]}
            value={
              data[header.field] || data[header.field] === 0
                ? parseFloat(data[header.field]).toLocaleString("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : ""
            }
            onChange={event => {
              let newVal = parseFloat(event.target.value.replace(/[^0-9\\.]/g, ""));
              setEditedData({
                ...editedData,
                [header.field]: isNaN(newVal) ? null : newVal,
              });
            }}
          />
        );
      } else if (headerType === "integer") {
        return (
          <Form.Control
            isInvalid={invalidSet[header.field]}
            value={
              data[header.field] || data[header.field] === 0 ? parseFloat(data[header.field]) : ""
            }
            onChange={event => {
              let newVal = parseInt(event.target.value.replace(/[^0-9\\.]/g, ""));
              setEditedData({
                ...editedData,
                [header.field]: isNaN(newVal) ? null : newVal,
              });
            }}
          />
        );
      } else if (headerType === "decimal") {
        return (
          <Form.Control
            type={"number"}
            isInvalid={invalidSet[header.field]}
            value={
              data[header.field] || data[header.field] === 0 ? parseFloat(data[header.field]) : ""
            }
            onChange={event => {
              let newVal = parseFloat(event.target.value);
              setEditedData({
                ...editedData,
                [header.field]: isNaN(newVal) ? null : newVal,
              });
            }}
          />
        );
      } else if (headerType === "week") {
        return (
          <SingleDatePicker
            mondayOnly
            date={data[header.field]}
            isOutsideRange={() => false}
            onChange={date => {
              if (date) {
                setEditedData({
                  ...editedData,
                  [header.field]: date,
                });
              }
            }}
          />
        );
      } else if (headerType === "day") {
        return (
          <SingleDatePicker
            showClearDate
            date={data[header.field]}
            isOutsideRange={() => false}
            onChange={date => {
              setEditedData({
                ...editedData,
                [header.field]: date,
              });
            }}
            isDayBlocked={header.restriction}
          />
        );
      } else if (headerType === "checkbox") {
        return (
          <CheckBox
            checked={data[header.field]}
            onCheck={checked => {
              setEditedData({ ...editedData, [header.field]: Number(checked) });
            }}
          />
        );
      } else if (headerType === "targetingCheckBox") {
        return (
          <TargetingCheckBox
            checkBoxState={data[header.field]}
            onCheck={checkBoxState => {
              setEditedData({ ...editedData, [header.field]: checkBoxState });
            }}
          ></TargetingCheckBox>
        );
      } else if (headerType === "file") {
        return (
          <BPMFileInput
            buttonText="Upload PDF"
            id="fileInput"
            name="fileInput"
            onChange={e => {
              const uploadedFile = e.target.files;
              const file = uploadedFile?.[0];
              const fileName =
                !rowData.pdf || rowData.pdf === "" ? `${className}/${uuid.v4()}.pdf` : rowData.pdf;

              setEditedData({ ...editedData, [header.field]: fileName });
              setPdfToUpload({ fileName: fileName, file: file } as FileToUpload);
            }}
          />
        );
      } else if (headerType === "query") {
        return (
          <Form.Control
            className="modal-edit-text-area"
            isInvalid={invalidSet[header.field]}
            as="textarea"
            defaultValue={data[header.field]}
            key={header.field}
            readOnly={header.readOnly}
            onChange={e =>
              setEditedData({
                ...editedData,
                [header.field]: e.target.value ? e.target.value : null,
              })
            }
          />
        );
      }

      return (
        <Form.Control
          isInvalid={invalidSet[header.field]}
          type={"text"}
          defaultValue={data[header.field]}
          key={header.field}
          readOnly={header.readOnly}
          onChange={e =>
            setEditedData({
              ...editedData,
              [header.field]: e.target.value ? e.target.value.replace(/,/g, "").trim() : null,
            })
          }
        />
      );
    },
    [data, editedData, selectorOptions, invalidSet, className, rowData]
  );

  return (
    <Modal
      size="lg"
      keyboard={true}
      show={true}
      onHide={onCancel}
      className="constraintViewEditModalForBudgets"
    >
      <Modal.Header closeButton>
        <Modal.Title>{name}Pacing Goal</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        {R.values(
          R.map(
            row => (
              <div key={R.path([0, "modalRow"], row)} className="modalRow">
                {row.map(header => (
                  <Form.Group
                    key={header.field}
                    style={{
                      flex: 1, // Fill the width of the container
                      width: "100%", // Ensure it spans the full row
                    }}
                  >
                    {header.type !== "" && (
                      <Form.Label className="modalHeaderLabel">
                        {header.label}
                        {header.infoTextParagraphs && (
                          <InfoTooltip size="sm">
                            {header.infoTextParagraphs.map(paragraph => (
                              <div className="infoTextParagraph" key={paragraph}>
                                {paragraph}
                              </div>
                            ))}
                          </InfoTooltip>
                        )}
                      </Form.Label>
                    )}
                    {makeInputElement(header)}
                  </Form.Group>
                ))}
              </div>
            ),
            R.sortBy(
              elem => elem[0].modalRow as R.Ord,
              R.values(R.groupBy(elem => R.prop("modalRow", elem) as string, headers))
            )
          )
        )}
        {shouldShowFooterText && (
          <div className="alertContainer">
            <Alert className="invalidText">{footerText}</Alert>
          </div>
        )}
        {shouldShowInvalidText && (
          <div className="alertContainer">
            <Alert className="invalidText" variant="danger">
              {invalidText}
            </Alert>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="modalControls">
        {enableDelete && (
          <Button variant="danger" className="delete" onClick={onDelete}>
            Delete
          </Button>
        )}
        {customFooterButtons &&
          !isAddingNewRow &&
          customFooterButtons.map(button => {
            return (
              <Button
                variant={button.variant}
                onClick={() => button.onClick(rowData)}
                key={button.label}
              >
                {button.label}
              </Button>
            );
          })}
        <Button variant="dark" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="sendButton"
          disabled={!R.isEmpty(invalidSet)}
          onClick={onSave}
        >
          <span>Save</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

interface CustomFooterButton<T> {
  label: string | null | undefined;
  onClick: (rowData: T) => {};
  variant: string;
}

interface ModalEditTableForBudgetsProps<T> {
  checkIsValid?: ((elem: T) => Promise<boolean>) | ((data: T) => boolean) | null;
  checkShowFooter?: ((elem: T) => boolean) | null;
  className: string;
  customFooterButtons?: CustomFooterButton<T>[];
  defaultAdvancedFilter?: boolean;
  defaultNewRow?: Partial<T>;
  deletedRows?: T[];
  dynamicTokens?: string[];
  enableActionColumn?: boolean;
  enableAdd?: boolean;
  enableDelete?: boolean;
  filterBar?: boolean;
  filterBar2?: boolean;
  headers: Header[];
  invalidText?: string;
  footerText?: string;
  name?: string | JSX.Element;
  onFilteredDataChange?: (value?) => {} | void;
  readOnly?: boolean;
  rowHeight?: number;
  selectorOptions: Record<string, SelectorOption[]>;
  setDeletedRows?: Dispatch<any[]>; // Use any to allow arrow function and setStateAction
  setTableData: Dispatch<any[]>;
  showModalOnAdd?: boolean;
  tableData: T[];
  totals?: Partial<TableDataType>;
  totalsRenderer?: CellRenderer<Element | number | string | undefined>;
  hasPendingChanges: boolean;
  setShowPendingChanges: StateSetter<boolean>;
  saveChanges: () => void;
  isSaving: boolean;
  clearAllChanges: () => void;
  downloadData: any[];
  company: string;
  customSegmentsList: string[];
  importFunction: (file: File) => Promise<void>;
}

export const ModalEditTableForBudgets = <T extends TableDataType>(
  props: ModalEditTableForBudgetsProps<T>
): JSX.Element => {
  const {
    checkIsValid = null,
    checkShowFooter = null,
    className,
    customFooterButtons = [],
    defaultAdvancedFilter = false,
    defaultNewRow = {} as T,
    deletedRows = [],
    dynamicTokens = [],
    enableActionColumn = false,
    enableAdd = true,
    enableDelete = true,
    filterBar = false,
    filterBar2 = false,
    headers,
    invalidText = "",
    footerText = "",
    name = "",
    onFilteredDataChange,
    readOnly = false,
    rowHeight = ROW_HEIGHT,
    selectorOptions,
    setDeletedRows = () => [],
    setTableData,
    showModalOnAdd = false,
    tableData,
    totals,
    totalsRenderer,
    hasPendingChanges,
    setShowPendingChanges,
    saveChanges,
    isSaving,
    clearAllChanges,
    downloadData,
    company,
    customSegmentsList,
    importFunction,
  } = props;

  const [showEditRow, setShowEditRow] = useState<Partial<T>>();
  const [isAddingNewRow, setIsAddingNewRow] = useState<boolean>(false);
  const [showImportModal, setShowImportModal] = useState(false);

  const handleImportModalClose = () => {
    setShowImportModal(false);
  };

  // Since BPMTable does not consistently give us back the row index of the data being manipulated, an index variable is instead
  // added to each row in the below useLayoutEffect and is automatically refreshed on any modification to tableData. As a result,
  // modifications to the table do not need to maintain index manually.
  useLayoutEffect(() => {
    const indexesAreContiguous = R.addIndex(R.map)(
      (row: any, index: number) => !R.isNil(row.index) && index === row.index,
      tableData
    );

    if (indexesAreContiguous.includes(false)) {
      const newTableData: T[] = R.addIndex(R.map)(
        (row: any, index: number) => ({ ...row, index }),
        tableData
      );
      setTableData(newTableData);
    }
  }, [tableData, setTableData]);

  const setRowData = useCallback(
    (rowData, oldIndex, newIndex) => {
      for (let header of headers) {
        if (
          !(rowData[R.defaultTo("", header.field)] === 0) &&
          !rowData[R.defaultTo("", header.field)] &&
          typeof header.default !== "undefined"
        ) {
          rowData[R.defaultTo("", header.field)] = header.default;
        }
      }

      let newTableData;

      oldIndex = R.isNil(oldIndex) ? -1 : oldIndex;
      newIndex = R.isNil(newIndex) ? -1 : newIndex;
      rowData.lastmodified = Dfns.formatISO(new Date());

      if (oldIndex === -1 && newIndex === -1) {
        newTableData = tableData;
      } else if (oldIndex === -1) {
        const clampedNewIndex = R.clamp(0, tableData.length, newIndex);
        newTableData = R.insert(clampedNewIndex, rowData, tableData);
      } else if (newIndex === -1) {
        const clampedOldIndex = R.clamp(0, tableData.length - 1, oldIndex);
        newTableData = clampedOldIndex === oldIndex ? R.remove(oldIndex, 1, tableData) : tableData;
      } else {
        const clampedOldIndex = R.clamp(0, tableData.length - 1, oldIndex);
        const clampedNewIndex = R.clamp(0, tableData.length - 1, newIndex);
        newTableData =
          clampedOldIndex === oldIndex
            ? R.pipe(R.update(oldIndex, rowData), R.move(oldIndex, clampedNewIndex))(tableData)
            : tableData;
      }
      setTableData(newTableData);
    },
    [tableData, setTableData, headers]
  );

  const toggleRow = useCallback(
    ({ row }) => {
      setTableData(
        R.map(curr => {
          if (curr === row) {
            return {
              ...curr,
              selected: !row.selected,
            };
          } else {
            return { ...curr };
          }
        }, tableData)
      );
    },
    [tableData, setTableData]
  );

  const tableHeaders = useMemo(
    () =>
      R.filter(
        elem => !R.isNil(elem),
        R.concat(
          enableActionColumn && enableDelete
            ? ([
                {
                  name: "action",
                  label: "",
                  width: 80,
                  renderer: row => (
                    <div className="rowActions">
                      {row.isError && (
                        <OverlayTrigger
                          placement={OverlayTrigger.PLACEMENTS.RIGHT.CENTER}
                          overlay={<Tooltip id={uuid.v4()}>{row.isError}</Tooltip>}
                        >
                          <div className="errorIndicator">
                            <MdError />
                          </div>
                        </OverlayTrigger>
                      )}
                      <Button variant="link" onClick={() => toggleRow({ row })}>
                        {row.selected ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
                      </Button>
                      <Button
                        variant="link"
                        disabled={row.index === 0}
                        onClick={() => setRowData(row, row.index, row.index - 1)}
                      >
                        <MdArrowUpward />
                      </Button>
                      <Button
                        variant="link"
                        disabled={row.index === tableData.length - 1}
                        onClick={() => setRowData(row, row.index, row.index + 1)}
                      >
                        <MdArrowDownward />
                      </Button>
                    </div>
                  ),
                },
              ] as Header[])
            : [],
          R.map(header => {
            return {
              ...header,
              width: R.defaultTo(120, header.width),
              minFlexWidth: R.defaultTo(200, header.minFlexWidth),
              name: header.field,
              renderer: data => {
                if (header.renderer) {
                  return (
                    <div
                      className={"modalEditTableCell modalEditTableCustomRendererCell"}
                      onClick={() => {
                        if (!readOnly && !header.uneditable) {
                          setShowEditRow(data);
                        }
                      }}
                    >
                      {header.renderer(data, header.field)}
                    </div>
                  );
                }

                let displayValue: boolean | string | JSX.Element = "-";

                if (!R.isNil(data[R.defaultTo("", header.field)]) && !header.isMulti) {
                  displayValue = renderForType(
                    header.type as string,
                    data[R.defaultTo("", header.field)],
                    header.isMulti as boolean
                  );
                } else if (
                  !R.isNil(data[R.defaultTo("", header.field)]) &&
                  header.isMulti &&
                  data[R.defaultTo("", header.field)].length === 1
                ) {
                  displayValue = <span>[{data[R.defaultTo("", header.field)]}]</span>;
                } else if (
                  !R.isNil(data[R.defaultTo("", header.field)]) &&
                  header.isMulti &&
                  data[R.defaultTo("", header.field)].length > 1
                ) {
                  displayValue = (
                    <OverlayTrigger
                      placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
                      overlay={
                        <Tooltip id={uuid.v4()}>
                          {data[R.defaultTo("", header.field)].join(", ")}
                        </Tooltip>
                      }
                    >
                      <span>{data[R.defaultTo("", header.field)].length} selected</span>
                    </OverlayTrigger>
                  );
                }

                return (
                  <div
                    className={"modalEditTableCell"}
                    onClick={() => {
                      if (!readOnly) {
                        setShowEditRow(data);
                      }
                    }}
                  >
                    {displayValue}
                  </div>
                );
              },
            };
          }, headers) as Header[]
        ) as Header[]
      ),
    [enableActionColumn, enableDelete, headers, tableData.length, toggleRow, setRowData, readOnly]
  );

  return (
    headers && (
      <div className={cn("budgetIntakeToolModalEditTable", className)}>
        <div className="tableHeader">
          <div className="tableName">{name}</div>
          <div className="tableActions">
            {R.filter((elem: T) => !R.isNil(elem) && elem.selected === true, tableData).length >
              0 && (
              <Button
                className="deleteButton"
                size="sm"
                onClick={() => {
                  setTableData(
                    R.filter((row: T) => !R.isNil(row) && row.selected === true, tableData)
                  );
                }}
                variant="outline-danger"
              >
                <MdDelete />
              </Button>
            )}
            <ButtonIcon
              type={ButtonType.FILLED}
              design="secondary"
              variant={ButtonFrameworkVariant.LEADING_ICON}
              icon={<MdOutlineAvTimer />}
              onClick={() => {
                setShowPendingChanges(prev => !prev);
              }}
              disabled={!hasPendingChanges}
              size="sm"
            >
              View Pending Changes
            </ButtonIcon>
            <ButtonIcon
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.LEADING_ICON}
              icon={<MdOutlineUploadFile />}
              onClick={() => setShowImportModal(true)}
              size="sm"
            >
              Bulk Upload
            </ButtonIcon>
            {enableAdd && (
              <ButtonIcon
                type={ButtonType.FILLED}
                variant={ButtonFrameworkVariant.LEADING_ICON}
                icon={<MdAdd />}
                onClick={() => {
                  if (showModalOnAdd && !readOnly) {
                    setShowEditRow({ ...defaultNewRow, index: 0 });
                    setIsAddingNewRow(true);
                  }
                  const newTableData = R.concat([defaultNewRow], tableData);
                  setTableData(newTableData);
                }}
                size="sm"
              >
                Add New Budget / Goal
              </ButtonIcon>
            )}

            <ButtonIcon
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.ICON_ONLY}
              icon={<MdOutlineFileDownload />}
              onClick={() => {
                exportToExcelWithDropDown(
                  downloadData,
                  company,
                  customSegmentsList,
                  selectorOptions
                );
              }}
              size="sm"
            ></ButtonIcon>
          </div>
        </div>
        <div className="outerTableContainer">
          <BudgetIntakeToolTable
            data={tableData}
            filterBar={filterBar}
            filterBar2={filterBar2}
            defaultAdvancedFilter={defaultAdvancedFilter}
            dynamicTokens={dynamicTokens}
            headerHeight={ROW_HEIGHT}
            headers={tableHeaders}
            minColumnWidth={20}
            noRowsRenderer={() => <div className="noRowsRenderer">No rows to show</div>}
            rowHeight={rowHeight}
            rowHoverClass="hovered"
            totals={totals}
            totalsRenderer={totalsRenderer}
            onFilteredDataChange={onFilteredDataChange}
          />
        </div>
        {showEditRow && (
          <ModalRowEditor<T>
            checkIsValid={checkIsValid}
            checkShowFooter={checkShowFooter}
            customFooterButtons={customFooterButtons}
            deletedRows={deletedRows}
            enableDelete={enableDelete}
            headers={headers}
            invalidText={invalidText}
            footerText={footerText}
            isAddingNewRow={isAddingNewRow}
            name={name}
            onHide={() => setShowEditRow(undefined)}
            rowData={showEditRow as T}
            selectorOptions={selectorOptions}
            setDeletedRows={setDeletedRows}
            setIsAddingNewRow={setIsAddingNewRow}
            setRowData={(rowData, oldIndex, newIndex) => {
              setRowData(rowData, oldIndex, newIndex);
              setShowEditRow(undefined);
            }}
            className={className}
          />
        )}
        {showImportModal && (
          <ImportModal
            importType="bulk"
            importFunction={importFunction}
            onClose={handleImportModalClose}
          />
        )}
        {hasPendingChanges && (
          <div className="tableFooter">
            <ButtonIcon
              type={ButtonType.OUTLINED}
              variant={ButtonFrameworkVariant.LEADING_ICON}
              onClick={clearAllChanges}
              icon={<MdDelete />}
              size="sm"
            >
              Discard Changes
            </ButtonIcon>
            <ButtonIcon
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.LEADING_ICON}
              onClick={saveChanges}
              disabled={isSaving || !hasPendingChanges}
              icon={<MdSave />}
              size="sm"
            >
              {isSaving ? <Spinner /> : "Save"}
            </ButtonIcon>
          </div>
        )}
      </div>
    )
  );
};
