import { MiscLambdaFetch, awaitJSON } from "../../../utils/fetch-utils";
import { RouteComponentProps } from "@reach/router";
import { useSetError } from "../../../redux/modals";
import * as R from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "../../../Components";

const REFRESH_TIME = 1000 * 60 * 8; // 30 days in ms
const TABLEAU_URL =
  "https://10ay.online.tableau.com/t/tinuiti/views/WIPPTPathingAnalysisDashboardV3/CampaignPath";

const Tableau = ({ path }: RouteComponentProps): JSX.Element => {
  const [token, setToken] = useState<string>();
  const setError = useSetError();

  const getJwt = useCallback(async () => {
    try {
      const res = await MiscLambdaFetch("/tableauToken", {
        params: { secret: "prod/tableauJWTSecrets" },
      });
      const token = await awaitJSON(res);
      setToken(token);
    } catch (e) {
      const reportError = e as Error;
      setError({
        title: "Error",
        message: `Error fetching authentication token. ${reportError.message}`,
        reportError,
      });
    }
  }, [setError]);

  useEffect(() => {
    if (R.isNil(token)) {
      getJwt();
    } else {
      setTimeout(() => {
        getJwt();
      }, REFRESH_TIME);
    }
  }, [getJwt, token]);

  return (
    <div
      className="tableauEmbedded"
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {token ? (
        <tableau-viz
          hideTabs
          id="tableauViz"
          iframe-auth
          src={TABLEAU_URL}
          token={token}
          toolbar={"bottom"}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Tableau;
