import React, { useContext, useEffect, useState, useCallback } from "react";
import { FormContext, metaBuyingFormDefault } from "../MetaBuyingContext";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { MetaBuyingNamePreview } from "./MetaBuyingNamePreview";
import { MetaBuyingFooterControls } from "./MetaBuyingFooterControls";
import { MetaBuyingOptionsNoteCard } from "./MetaBuyingOptionsNoteCard";
import { RouteComponentProps } from "@reach/router";
import {
  filterSegmentationIds,
  generateNameFields,
  mapLabelsByValues,
  formatBudget,
  updateBudget,
} from "../MetaBuyingUtils";
import { MetaBuyingTabs } from "../MetaBuyingTabs";
import {
  CreationTab,
  FormField,
  SpecialAdCategory,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import * as R from "ramda";
import MetaBuyingNameFields from "./MetaBuyingNameFields";
import {
  CAMPAIGN,
  TAB_CAMPAIGN,
  CAMPAIGN_DAILY_BUDGET,
  CAMPAIGN_LIFETIME_BUDGET,
  AD_SET_BUDGET,
  SEGMENTATION_ID,
  OBJECTIVE_TYPES,
  BUDGET_TYPES,
  BID_STRATEGY_OPTIONS,
  OPTIONS_NOTE_CARD_HEADERS,
  OPTIONS_NOTE_CARD_OPTIONS,
  OPTIONS_NOTE_CARD_TEXTS,
  SPECIAL_AD_CATEGORY_OPTIONS,
  SPECIAL_AD_CATEGORIES,
  ALL,
} from "../MetaBuyingConstants";

interface MetaBuyingCampaignProps {
  client: string;
  namingConvention: number[];
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  showReviewModal: () => void;
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  validateForms: (
    metaBuyingForm: typeof metaBuyingFormDefault,
    navigate: () => void,
    nameFields: Record<string, any>,
    tabToValidate?: CreationTab
  ) => void;
  creationTabs: CreationTab[];
  granularity: string;
  dimensions: Record<string, any>;
  allNameFields: Record<string, any>;
  saveNameFields: (nameFields: Record<string, any>) => void;
}

export const MetaBuyingCampaign = ({
  selectedAdAccount,
  client,
  namingConvention,
  tabIndex,
  setTabIndex,
  showReviewModal,
  validateForms,
  creationTabs,
  granularity,
  dimensions,
  allNameFields,
  saveNameFields,
}: MetaBuyingCampaignProps & RouteComponentProps): JSX.Element => {
  const { metaBuyingForm, setMetaBuyingForm } = useContext(FormContext);

  const metaCampaignForm = metaBuyingForm.campaign;
  const metaAdSetForm = metaBuyingForm.ad_set;

  const { business_manager } = selectedAdAccount;

  const segmentationDimensionId = R.keys(
    R.filter(dimension => dimension.type === SEGMENTATION_ID, dimensions)
  )[0];

  const isSegmentedLevel = R.includes(Number(segmentationDimensionId), namingConvention);

  const [nameFields, setNameFields] = useState<Record<string, any>>(
    generateNameFields(namingConvention, allNameFields)
  );

  const setNameField = useCallback(
    (id, value) => {
      let newNameFields = R.clone(nameFields);
      newNameFields[id] = value;
      setNameFields(newNameFields);
    },
    [nameFields]
  );

  const setMetaCampaignForm = useCallback(
    value =>
      setMetaBuyingForm((current: any): typeof metaBuyingFormDefault => {
        return { ...current, campaign: value };
      }),
    [setMetaBuyingForm]
  );

  const updateFormField = useCallback(
    (key: string, value: FormField) =>
      setMetaBuyingForm((current: any): typeof metaBuyingFormDefault => {
        return { ...current, campaign: { ...current.campaign, [key]: value } };
      }),
    [setMetaBuyingForm]
  );

  useEffect(() => {
    if (isSegmentedLevel) {
      const filteredSegIds = filterSegmentationIds(nameFields, dimensions, segmentationDimensionId);
      const selectedSegId = nameFields[segmentationDimensionId];
      if (!R.includes(selectedSegId, filteredSegIds) && !R.isEmpty(selectedSegId)) {
        setNameField(segmentationDimensionId, "");
      }
    }
  }, [nameFields, segmentationDimensionId, dimensions, setNameField, isSegmentedLevel]);

  useEffect(() => {
    let newMetaCampaignForm = R.clone(metaCampaignForm);
    const newCampaignName = namingConvention.map(dimensionId => nameFields[dimensionId]).join("_");
    if (!R.equals(newCampaignName, metaCampaignForm.campaign_name)) {
      newMetaCampaignForm.campaign_name = newCampaignName;
    }
    if (
      (metaCampaignForm.budget_type === CAMPAIGN_DAILY_BUDGET ||
        metaCampaignForm.budget_type === CAMPAIGN_LIFETIME_BUDGET) &&
      R.isNil(metaCampaignForm.budget)
    ) {
      newMetaCampaignForm.budget = 2000;
    } else if (
      metaCampaignForm.budget_type === AD_SET_BUDGET &&
      !R.isNil(metaCampaignForm.budget)
    ) {
      newMetaCampaignForm.budget = null;
    }
    if (!R.equals(newMetaCampaignForm, metaCampaignForm)) {
      setMetaCampaignForm(newMetaCampaignForm);
    }
  }, [metaCampaignForm, setMetaCampaignForm, nameFields, namingConvention]);

  return (
    <>
      <MetaBuyingTabs
        tabIndex={tabIndex}
        creationTabs={creationTabs}
        validate={index => {
          if (index <= tabIndex) {
            saveNameFields(nameFields);
            setTabIndex(index);
          } else {
            validateForms(metaBuyingForm, () => setTabIndex(index), nameFields, TAB_CAMPAIGN);
          }
        }}
      />
      <div className="metaBuyingContainer">
        <div className="metaBuyingCard">
          <div className="metaBuyingHeader">
            <h2>Campaign</h2>
            <div className="metaBuyingNameWrapper">
              Name:
              <MetaBuyingNamePreview
                dimensions={dimensions}
                nameFields={nameFields}
                namingConvention={namingConvention}
              />
            </div>
          </div>

          <div className="metaBuyingFormBody">
            <div className="metaBuyingFormSection">
              <h3>Campaign Name Construction</h3>
              <MetaBuyingNameFields
                namingConvention={namingConvention}
                dimensions={dimensions}
                nameFields={nameFields}
                segmentationDimensionId={segmentationDimensionId}
                client={client}
                agency={business_manager}
                setNameField={setNameField}
                granularity={granularity}
              />
            </div>
            <div className="metaBuyingFormSection">
              <h3>Campaign Options</h3>
              <Form.Group>
                <Form.Label>Campaign Objective</Form.Label>
                <DropdownButton
                  title={
                    //@ts-ignore
                    mapLabelsByValues(OBJECTIVE_TYPES)[metaCampaignForm.objective] || ""
                    //@ts-ignore
                  }
                  onSelect={value => updateFormField("objective", value)}
                  disabled={true}
                >
                  {OBJECTIVE_TYPES.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Special Ad Categories</Form.Label>
                <DropdownButton
                  title={
                    <div className="title">
                      {metaCampaignForm.special_ad_categories.map(category => (
                        <div key={category}>
                          {
                            //@ts-ignore
                            mapLabelsByValues(SPECIAL_AD_CATEGORY_OPTIONS)[category]
                            //@ts-ignore
                          }
                        </div>
                      ))}
                    </div>
                  }
                  onSelect={value => {
                    let newMetaCampaignForm = R.clone(metaCampaignForm);
                    let newMetaAdSetForm = R.clone(metaAdSetForm);
                    if (R.includes(value, metaCampaignForm.special_ad_categories)) {
                      newMetaCampaignForm.special_ad_categories = R.remove(
                        R.findIndex(elem => elem === value, metaCampaignForm.special_ad_categories),
                        1,
                        metaCampaignForm.special_ad_categories
                      );
                    } else {
                      newMetaCampaignForm.special_ad_categories.push(value);
                    }
                    const limitAudience = R.any(
                      (category: SpecialAdCategory) =>
                        SPECIAL_AD_CATEGORIES[category].limitAudienceTargeting,
                      newMetaCampaignForm.special_ad_categories
                    );
                    if (limitAudience) {
                      newMetaAdSetForm.age_min = 18;
                      newMetaAdSetForm.age_max = 65;
                      newMetaAdSetForm.genders = ALL;
                    }
                    setMetaBuyingForm({
                      ...metaBuyingForm,
                      campaign: newMetaCampaignForm,
                      ad_set: newMetaAdSetForm,
                    });
                  }}
                >
                  {SPECIAL_AD_CATEGORY_OPTIONS.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      <div
                        className={
                          R.includes(value, metaCampaignForm.special_ad_categories)
                            ? "selected"
                            : ""
                        }
                      >
                        {label}
                      </div>
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
            </div>
            <div className="metaBuyingFormSection">
              <h3>Budget</h3>
              <Form.Group>
                <Form.Label>Budget Type</Form.Label>
                {BUDGET_TYPES[CAMPAIGN].map(({ label, value }) => (
                  <Form.Check
                    key={label}
                    type="radio"
                    label={label}
                    checked={metaCampaignForm.budget_type === value}
                    id={value}
                    onChange={() => {
                      updateFormField("budget_type", value);
                      if (R.equals(value, AD_SET_BUDGET)) {
                        updateFormField("bid_strategy", null);
                      }
                    }}
                  />
                ))}
              </Form.Group>
              {(metaCampaignForm.budget_type === CAMPAIGN_DAILY_BUDGET ||
                metaCampaignForm.budget_type === CAMPAIGN_LIFETIME_BUDGET) && (
                <>
                  <Form.Group>
                    <Form.Label>Bid Strategy</Form.Label>
                    <DropdownButton
                      title={
                        //@ts-ignore
                        mapLabelsByValues(BID_STRATEGY_OPTIONS)[metaCampaignForm.bid_strategy] || ""
                        //@ts-ignore
                      }
                      onSelect={value => updateFormField("bid_strategy", value)}
                    >
                      {BID_STRATEGY_OPTIONS.map(({ label, value }) => (
                        <Dropdown.Item key={value} eventKey={value}>
                          {label}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Budget</Form.Label>
                    <Form.Control
                      value={formatBudget(metaCampaignForm.budget)}
                      onChange={e => updateBudget(e.target.value, updateFormField)}
                    />
                  </Form.Group>
                </>
              )}
            </div>
            <MetaBuyingFooterControls
              nextTabName={creationTabs[tabIndex + 1]}
              handleReviewClick={() => validateForms(metaBuyingForm, showReviewModal, nameFields)}
              handleNextClick={
                creationTabs[tabIndex + 1]
                  ? () =>
                      validateForms(
                        metaBuyingForm,
                        () => setTabIndex(tabIndex + 1),
                        nameFields,
                        TAB_CAMPAIGN
                      )
                  : undefined
              }
            />
          </div>
        </div>

        <MetaBuyingOptionsNoteCard
          header={OPTIONS_NOTE_CARD_HEADERS[CAMPAIGN]}
          text={OPTIONS_NOTE_CARD_TEXTS[CAMPAIGN]}
          options={OPTIONS_NOTE_CARD_OPTIONS[CAMPAIGN]}
        />
      </div>
    </>
  );
};

export default MetaBuyingCampaign;
