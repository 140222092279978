import React from "react";
import * as R from "ramda";
import { CLIENT, AGENCY, CURRENT_DATE } from "../MetaBuyingConstants";

interface MetaBuyingNamePreviewProps {
  dimensions: Record<string, any>;
  nameFields: Record<string, any>;
  namingConvention: number[];
  inheritedFields?: number[];
  existingAdSetName?: string;
}

export const MetaBuyingNamePreview = ({
  dimensions,
  nameFields,
  namingConvention,
  inheritedFields,
  existingAdSetName,
}: MetaBuyingNamePreviewProps): JSX.Element => (
  <span className="metaBuyingNamePreview">
    {existingAdSetName
      ? existingAdSetName
      : namingConvention.map((dimensionId, index) => {
          return R.isEmpty(nameFields[dimensionId]) ? (
            <span key={index} className="placeholder">
              {dimensions[dimensionId].name.replace(" ", "")}
              {index < namingConvention.length - 1 ? "_" : ""}
            </span>
          ) : (
            <span
              key={index}
              className={
                ((inheritedFields && !R.includes(dimensionId, inheritedFields)) ||
                  R.isNil(inheritedFields)) &&
                dimensions[dimensionId].type !== CLIENT &&
                dimensions[dimensionId].type !== AGENCY &&
                dimensions[dimensionId].type !== CURRENT_DATE
                  ? "constructed"
                  : ""
              }
            >
              {nameFields[dimensionId]}
              {index < namingConvention.length - 1 ? "_" : ""}
            </span>
          );
        })}
  </span>
);
