import "./RetailOpsChannels.scss";
import React, { useCallback, useEffect, useState } from "react";
import * as R from "ramda";
import { Button, ButtonType, Dropdown, Page, Spinner } from "../Components";
import RetailOpsForm from "./RetailOpsForm";
import { useCompanyInfo } from "../redux/company";
import { BPMTable } from "../Components/StickyTable/BPMTable";
import { AmazonLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import { COUNTRY_TO_MARKETPLACE_ID } from "@blisspointmedia/bpm-types/dist/AmazonSPAPI";

const RetailOpsChannels: React.FC = () => {
  const setError = useSetError();
  const companyInfo = useCompanyInfo();
  const { mobiusx_account_id: accountId, cid: company } = companyInfo;
  const [accountRows, setAccountRows] = useState<any[] | null>(null);
  const [channelType, setChannelType] = useState<string>("");
  const [applicationType, setApplicationType] = useState("");
  const [marketplace, setMarketplace] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const [redirectUrl, setRedirectUrl] = useState<string>("");

  const checkAuthorization = useCallback(async () => {
    const res = await AmazonLambdaFetch("/checkSellingPartnerAuthorization", {
      params: { company, applicationType },
    });
    const data = await awaitJSON<string>(res);
    if (data.substring(0, 5) === "https") {
      setRedirectUrl(data);
    }
  }, [company, applicationType]);

  const signInToAmazon = useCallback(() => {
    (() => {
      try {
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed redirect to Amazon: ${reportError.message}`,
          reportError,
        });
      }
    })();
  }, [redirectUrl, setError]);

  const oauthProcess = useCallback(async () => {
    setLoading(true);
    await checkAuthorization();
    setLoading(false);
  }, [checkAuthorization]);

  useEffect(() => {
    if (redirectUrl) {
      signInToAmazon();
    }
  }, [redirectUrl, signInToAmazon]);

  // Check for URL paramters after redirect back from Amazon. If they exist, hit the lambda to generate and store a new refresh token.
  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(window.location.search);
      const spapiOauthCode = params.get("spapi_oauth_code");
      const sellingPartnerId = params.get("selling_partner_id");

      if (spapiOauthCode && sellingPartnerId) {
        await AmazonLambdaFetch("/generateRefreshToken", {
          method: "POST",
          body: JSON.stringify({
            company,
            spapiOauthCode,
            sellingPartnerId,
            applicationType,
            marketplace,
          }),
        });
      }
    })();
  }, [company, applicationType, marketplace]);

  useEffect(() => {
    (async () => {
      if (!accountRows) {
        const res = await AmazonLambdaFetch("/getSellingPartnerAccounts", {
          params: { company },
        });
        const data = await awaitJSON<any[]>(res);
        setAccountRows(data);
      }
    })();
  }, [company, applicationType, accountRows]);

  const TABLE_HEADERS = [
    {
      label: "Account Type",
      name: "account_type",
      flex: 1,
    },
    {
      label: "Account ID",
      name: "account_id",
      flex: 1,
    },
    {
      label: "Account Name",
      name: "account_name",
      flex: 1,
    },
    {
      label: "Secret Path",
      name: "secret_path",
      flex: 1,
    },
    {
      label: "Marketplace ID",
      name: "marketplace_id",
      flex: 1,
    },
    {
      label: "Marketplace",
      name: "marketplace",
      flex: 1,
    },
  ];

  return (
    <Page minHeight="600px" pageType="Retail Ops Channels" title="Retail Ops Channels">
      <div className="retail-ops-channel-page">
        <div className="retailOpsControls">
          <div className="amazonOAuthContainer">
            <div className="label">Amazon SPAPI OAuth</div>
            <div className="controls">
              <Dropdown
                onChange={option => setApplicationType(option)}
                options={[
                  { label: "Amazon Selling Parter - Vendor", value: "vendor" },
                  { label: "Amazon Selling Partner - Seller", value: "seller" },
                ]}
                placeholder="Selling Partner Type"
                value={applicationType}
              />
              <Dropdown
                onChange={option => setMarketplace(option)}
                options={Object.keys(COUNTRY_TO_MARKETPLACE_ID)}
                placeholder="Marketplace"
                value={marketplace}
              />
              <Button
                type={ButtonType.FILLED}
                onClick={oauthProcess}
                disabled={!applicationType || !marketplace}
              >
                {loading ? <Spinner /> : "Submit"}
              </Button>
            </div>
          </div>
        </div>
        {channelType && (
          <RetailOpsForm
            accountId={accountId}
            setChannelType={setChannelType}
            title={channelType}
          />
        )}

        <div className="basic-card">
          {R.isNil(accountRows) ? (
            <div>Loading...</div>
          ) : (
            <div className="table-wrapper" style={{ height: "650px" }}>
              <BPMTable
                data={accountRows || []}
                filterBar={false}
                headers={TABLE_HEADERS}
                noRowsRenderer={() => (
                  <div>
                    There are no existing SPAPI auth secets for this client. Add one by submitting
                    and authenticating above.
                  </div>
                )}
              />
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default RetailOpsChannels;
