import * as XLSX from "xlsx";
import * as ExcelJS from "exceljs";
import * as Dfns from "date-fns/fp";

interface BudgetIntakeRowInternalExcel {
  id: number;
  company: string;
  start_date: string;
  end_date: string;
  [key: string]: string | number;
  goal_name: string;
  goal_value: number;
}

const transformKeyToTitleCase = (key: string): string => {
  return key
    .split("_")
    .map((word, index) =>
      index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase()
    )
    .join(" ");
};

export const exportToExcelWithDropDown = async (
  rows: BudgetIntakeRowInternalExcel[],
  company: string,
  customSegmentList: string[],
  budgetIntakeSelectorOptionsPull: {}
): Promise<void> => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(company);

  const segmentHeader = customSegmentList.map(key => ({
    header: transformKeyToTitleCase(key),
    key: key,
  }));

  const headerRowOptions = [
    { header: "id", key: "id" },
    { header: "Start Date", key: "start_date" },
    { header: "End Date", key: "end_date" },
    ...segmentHeader,
    { header: "Goal Name", key: "goal_name" },
    { header: "Goal Value", key: "goal_value" },
  ];

  let processedRows: BudgetIntakeRowInternalExcel[] = [];
  for (let row of rows) {
    const customSegmentObject = customSegmentList.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {});

    const finalRow = {
      id: row.id,
      company: row.company,
      start_date: row.start_date,
      end_date: row.end_date,
      ...customSegmentObject,
      goal_name: row.goal_name,
      goal_value: row.goal_value,
    };
    processedRows.push(finalRow);
  }

  worksheet.columns = headerRowOptions;

  worksheet.addRows(processedRows);

  const listsWorksheet = workbook.addWorksheet("Lists");

  const sortedKeys = Object.keys(budgetIntakeSelectorOptionsPull)
    .filter(key => key !== "goalNameOptions")
    .sort()
    .concat("goalNameOptions");

  for (let col = 0; col < sortedKeys.length; col++) {
    const key = sortedKeys[col];
    const listOfOptions = budgetIntakeSelectorOptionsPull[key].map(obj => obj.value);
    const colLetter = String.fromCharCode(65 + col);

    listOfOptions.forEach((option, i) => {
      listsWorksheet.getCell(`${colLetter}${i + 1}`).value = option;
    });
  }

  for (let col = 0; col < sortedKeys.length; col++) {
    const key = sortedKeys[col];
    const listOfOptions = budgetIntakeSelectorOptionsPull[key].map(obj => obj.value);

    for (let row = 2; row < 1000; row++) {
      let currentCell = `${String.fromCharCode(65 + col + 3)}${row}`;
      const colLetter = String.fromCharCode(65 + col);
      const startRow = 1;
      const endRow = listOfOptions.length;

      const formulaRange = `Lists!$${colLetter}$${startRow}:$${colLetter}$${endRow}`;
      worksheet.getCell(currentCell).dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [formulaRange],
      };
    }
  }

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${company}.xlsx`;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const exportToExcel = (
  rows: BudgetIntakeRowInternalExcel[],
  company: string,
  customSegmentList: string[]
): void => {
  let processedRows: BudgetIntakeRowInternalExcel[] = [];
  const segmentHeader = customSegmentList.reduce((acc, key) => {
    acc[key] = "";
    return acc;
  }, {});

  const headerRowOptions = [
    {
      ...{ id: "", start_date: "", end_date: "" },
      ...segmentHeader,
      ...{ goal_name: "", goal_value: "" },
    },
  ];

  for (let row of rows) {
    const customSegmentObject = customSegmentList.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {});

    const finalRow = {
      id: row.id,
      company: row.company,
      start_date: row.start_date,
      end_date: row.end_date,
      ...customSegmentObject,
      goal_name: row.goal_name,
      goal_value: row.goal_value,
    };
    processedRows.push(finalRow);
  }

  let fileName = `${company}.xlsx`;

  // The sheet name can't exceed 31 characters.
  if (fileName.length > 31) {
    let diff = fileName.length - 31;
    fileName = fileName.substring(diff);
  }

  let worksheet =
    processedRows.length > 0
      ? XLSX.utils.json_to_sheet(processedRows)
      : XLSX.utils.json_to_sheet(headerRowOptions);

  let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };

  workbook.SheetNames.push(fileName);
  workbook.Sheets[fileName] = worksheet;

  XLSX.writeFile(workbook, fileName);
};

const mapRowToDisplay = (row: any, companyNew: string, customSegmentList: string[]) => {
  const { company, lastmodified } = row;
  const id = row.id || row.ID;
  const startDate =
    row.start_date ||
    row["Start Date"] ||
    row[" Start Date "] ||
    row["start date"] ||
    row[" start date "];
  const endDate =
    row.end_date || row["End Date"] || row[" End Date "] || row["end date"] || row[" end date "];
  const goalName =
    row.goal_name ||
    row["Goal Name"] ||
    row[" Goal Name "] ||
    row["goal name"] ||
    row[" goal name "];
  const goalValue =
    row.goal_value ||
    row["Goal Value"] ||
    row[" Goal Value "] ||
    row["goal value"] ||
    row[" goal value "];

  const startHead = {
    id: id,
    company: company ? company : companyNew,
    start_date: isOnlyDigits(startDate) ? excelNumberToDate(startDate) : startDate,
    end_date: isOnlyDigits(endDate) ? excelNumberToDate(endDate) : endDate,
  };
  const endHead = {
    goal_name: goalName,
    goal_value: Math.round(goalValue),
    lastmodified: lastmodified ? lastmodified : Dfns.format("yyyy-MM-dd hh:mma", new Date()),
  };
  const newRow = { ...startHead, ...endHead };
  const combinedObject = customSegmentList.reduce(
    (acc, key) => {
      if (key in row) {
        acc[key] = row[key];
      } else {
        acc[key] = null;
      }
      return acc;
    },
    { ...newRow }
  );
  return combinedObject;
};

export const excelNumberToDate = (date: number): string => {
  const excelSerialNumber = date;
  const excelBaseDate = new Date(1899, 11, 30);
  const dateNew = new Date(excelBaseDate.getTime() + excelSerialNumber * 24 * 60 * 60 * 1000);
  const newFormattedDate = `${dateNew.getFullYear()}-${(dateNew.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${dateNew.getDate().toString().padStart(2, "0")}`;
  return newFormattedDate;
};

export const isOnlyDigits = (date: string): boolean => {
  const isNum = /^\d+$/.test(date);
  return isNum;
};

const checkRowIsValid = (
  row: any,
  customSegmentList: string[],
  currentRowNumberInExcel: number
) => {
  const { start_date, end_date, goal_name, goal_value } = row;
  const missingFields: string[] = [];
  if (!start_date) {
    missingFields.push("Start Date");
  }
  if (!end_date) {
    missingFields.push("End Date");
  }
  if (!goal_name) {
    missingFields.push("Goal Name");
  }
  if (!goal_value) {
    missingFields.push("Goal Value");
  }

  const hasAtLeastOneSegment = customSegmentList.some(segment => row[segment] != null);
  if (!hasAtLeastOneSegment) {
    missingFields.push("at least one segment");
  }
  if (missingFields.length > 0) {
    throw new Error(
      `Row ${currentRowNumberInExcel + 1} is invalid. Missing fields: ${missingFields.join(
        ", "
      )}. Please ensure all required fields are filled.`
    );
  }

  const startDate = new Date(start_date);
  const endDate = new Date(end_date);
  if (startDate > endDate) {
    throw new Error("End Date should not be before start date");
  }
  return true;
};

const checkRowIsDiff = (oldRow: any, newRow: any, customSegmentList: string[]) => {
  Object.entries(newRow).forEach(([key, value]) => {
    if (value === undefined) {
      newRow[key] = null;
    }
  });
  customSegmentList.forEach(segment => {
    oldRow[segment] = oldRow[segment] || null;
    newRow[segment] = newRow[segment] || null;
  });

  let isSegmentUpdated = customSegmentList.some(segment => oldRow[segment] !== newRow[segment]);
  if (
    oldRow.company !== newRow.company ||
    oldRow.start_date !== newRow.start_date ||
    oldRow.end_date !== newRow.end_date ||
    isSegmentUpdated ||
    oldRow.goal_name !== newRow.goal_name ||
    oldRow.goal_value !== newRow.goal_value
  ) {
    return true;
  }
  return false;
};

const checkRowIsUnique = (
  oldMap: Record<string, any>,
  newRow: any,
  customSegmentList: string[]
) => {
  let isSegmentUpdated = false;
  for (let oldRow of Object.values(oldMap)) {
    customSegmentList.forEach(segment => {
      oldRow[segment] = oldRow[segment] || null;
      newRow[segment] = newRow[segment] || null;
    });

    isSegmentUpdated = customSegmentList.some(segment => oldRow[segment] !== newRow[segment]);
    if (
      oldRow.start_date === newRow.start_date &&
      oldRow.end_date === newRow.end_date &&
      !isSegmentUpdated &&
      oldRow.goal_name === newRow.goal_name
    ) {
      return false;
    }
  }
  return true;
};

export const processBulkImport = (
  file: ArrayBuffer,
  existingRows: any[],
  companyNew: string,
  customSegmentList: string[]
): any[] => {
  let updateRowsMap: Record<string, any> = {};
  let originalRowsMap: Record<string, any> = {};

  for (let row of existingRows) {
    if (row.id) {
      updateRowsMap[row.id] = row;
      originalRowsMap[row.id] = row;
    }
  }

  // Create a workbook using the imported file.
  const workbook = XLSX.read(file, { type: "buffer" });

  // Get first sheet of workbook
  const worksheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[worksheetName];

  // Convert worksheet to JSON
  const rows: Record<string, any>[] = XLSX.utils.sheet_to_json(worksheet);
  let importRows: any[] = [];
  let currentRowNumberInExcel = 1;
  for (let row of rows) {
    const newRow = mapRowToDisplay(row, companyNew, customSegmentList);
    checkRowIsValid(newRow, customSegmentList, currentRowNumberInExcel);
    currentRowNumberInExcel++;
    if (newRow && !newRow.id && checkRowIsUnique(originalRowsMap, newRow, customSegmentList)) {
      importRows.push(newRow);
    } else if (
      newRow.id in updateRowsMap &&
      newRow &&
      checkRowIsDiff(updateRowsMap[newRow.id], newRow, customSegmentList)
    ) {
      importRows.push(newRow);
    }
  }
  return importRows;
};
