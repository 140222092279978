import "./NavBar.scss";
import { awaitJSON, UsersLambdaFetch } from "../utils/fetch-utils";
import {
  ALL_EXTERNAL_TOOLS_LINKS,
  CHANNEL_ICONS,
  CHANNELS_LINKS,
  CHANNELS_MAP,
  CLIENT_TOOLS_HEADERS,
  currentNavBarItem,
  flattenRoutes,
  getChannelTools,
  getInternalTools,
  INTERNAL_TOOLS_HEADERS,
  removeDisabledRoutes,
  TOOLS_ICONS,
  TOOLS_LINKS,
} from "./locations";
import { CompanyInfo, CustomDashboardInfo } from "../redux/company";
import { Form, Tooltip } from "react-bootstrap";
import { Img, OverlayTrigger } from "../Components";
import { Link } from "@reach/router";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdClose,
  MdDocumentScanner,
  MdHomeFilled,
  MdLogout,
  MdMoreHoriz,
  MdSearch,
} from "react-icons/md";
import { ReactComponent as AllBackendToolsIcon } from "./Icons/All Backend Tools Icon.svg";
import { ReactComponent as MdKeyboardArrowDown } from "./Icons/DropdownArrowDown.svg";
import { ReactComponent as MdKeyboardArrowUp } from "./Icons/DropdownArrowUp.svg";
import { useExperimentContext } from "../utils/experiments/experiment-utils";
import { useSelector } from "react-redux";
import { useSetError } from "../redux/modals";
import * as R from "ramda";
import * as UserRedux from "../redux/user";
import * as uuid from "uuid";
import React, { ReactElement, useState, useEffect, useCallback, useMemo } from "react";
import useLocation from "../utils/hooks/useLocation";
import { TEST_COMPANIES } from "@blisspointmedia/bpm-types/dist/TestCompanies";
import { TINUITI_TEST_HOME_SERVICES } from "../MMM/MMMUtils";

interface MainNavBarItemProps {
  active?: boolean;
  cancel: boolean | undefined;
  company?: string;
  dropdownSelected?: boolean;
  expanded: boolean;
  hasDropdownItems?: boolean;
  icon: Element | ReactElement<any, any>;
  onClick?: (args?: any) => void;
  opensSubNavBar?: boolean;
  opensSubNavBarOnClick?: (args?: any) => void;
  overlayItem?: any;
  requiresClient?: boolean;
  selected?: boolean;
  title: string;
  to: string;
}
const MainNavBarItem: React.FC<MainNavBarItemProps> = ({
  active,
  cancel,
  company,
  dropdownSelected,
  expanded,
  hasDropdownItems = false,
  icon,
  onClick = () => {},
  opensSubNavBar,
  opensSubNavBarOnClick,
  overlayItem,
  requiresClient,
  selected,
  title,
  to,
}) => {
  const [customTrigger, setCustomTrigger] = useState<boolean>(false);
  const { location } = useLocation();
  const selectedClassName =
    expanded && selected
      ? "mainItemSelected"
      : expanded && dropdownSelected
      ? "mainItemDropdownSelected"
      : "";
  const toWithCompany = `${
    requiresClient &&
    !R.defaultTo(location.pathname.slice(1), to).includes(R.defaultTo("", company)) &&
    to
      ? `${company}/`
      : ""
  }${to ? to : location.pathname.slice(1)}`;

  const toAdjusted =
    (!requiresClient || (requiresClient && company)) && to
      ? toWithCompany
      : location.pathname.slice(1);

  const linkOnClick = e => {
    if (toAdjusted !== location.pathname.slice(1) && e && e.stopPropagation) {
      e.stopPropagation();
    }
    onClick();
    if (requiresClient && !company) {
      setCustomTrigger(true);
    }
  };

  useEffect(() => {
    if (cancel || company || !expanded) {
      setCustomTrigger(false);
    }
  }, [cancel, company, expanded]);

  const iconTitle = (
    <Link
      className={`mainNavBarItemIconTitle ${selectedClassName} ${
        opensSubNavBar && expanded ? "hasExpander" : ""
      }`}
      onClick={e => linkOnClick(e)}
      to={toAdjusted}
    >
      <div className={`mainNavBarItemIcon ${selectedClassName}`}>{icon}</div>
      {expanded && (
        <div
          className={`mainNavBarItemTitle ${
            opensSubNavBar ? "hasExpander" : ""
          } ${selectedClassName}`}
        >
          {title}
        </div>
      )}
    </Link>
  );
  const expander = expanded && opensSubNavBar && (
    <OverlayTrigger
      placement={OverlayTrigger.PLACEMENTS.RIGHT.CENTER}
      overlay={<Tooltip id={uuid.v4()}>Internal Tools</Tooltip>}
    >
      <div
        className={`mainNavBarItemExpander ${selectedClassName}`}
        onClick={() => {
          if (opensSubNavBarOnClick) {
            opensSubNavBarOnClick();
          }
        }}
      >
        <div className={`mainNavBarItemExpanderBlock ${selectedClassName}`}>
          <MdMoreHoriz />
        </div>
      </div>
    </OverlayTrigger>
  );

  const dropdownExpander = expanded && hasDropdownItems && !opensSubNavBar && (
    <div className="mainNavBarItemExpanderDropdown">
      {dropdownSelected ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
    </div>
  );

  const mainNavBarItem =
    opensSubNavBar || !expanded ? (
      <div
        className={`mainNavBarItem ${selectedClassName} ${expanded ? "Expanded" : ""} ${
          opensSubNavBar ? "hasExpander" : ""
        } ${active && !expanded ? "activePage" : ""}`}
      >
        {iconTitle}
        {expander}
      </div>
    ) : (
      <Link
        className={`mainNavBarItem ${selectedClassName} ${expanded ? "Expanded" : ""} ${
          opensSubNavBar ? "hasExpander" : ""
        }`}
        onClick={e => linkOnClick(e)}
        to={toAdjusted}
      >
        {iconTitle}
        {expander}
        {dropdownExpander}
      </Link>
    );

  return (
    <OverlayTrigger
      customTrigger={expanded ? customTrigger && !company : null}
      exitOnBackgroundClick={expanded}
      loseFocusOnScroll={false}
      onExit={() => setCustomTrigger(false)}
      overlay={
        expanded && !company ? (
          R.isNil(overlayItem) ? (
            <div id={uuid.v4()}></div>
          ) : (
            overlayItem
          )
        ) : (
          <Tooltip id={uuid.v4()}>{title}</Tooltip>
        )
      }
      placement={
        expanded && !company
          ? OverlayTrigger.PLACEMENTS.RIGHT.TOP
          : OverlayTrigger.PLACEMENTS.RIGHT.CENTER
      }
    >
      {mainNavBarItem}
    </OverlayTrigger>
  );
};

interface SubNavBarItemProps {
  cancel: boolean | undefined;
  company?: string;
  onClick?: () => void;
  requiresClient?: boolean;
  overlayItem?: any;
  title: string;
  to: string;
}

const SubNavBarItem: React.FC<SubNavBarItemProps> = ({
  cancel,
  company,
  onClick,
  overlayItem,
  requiresClient = false,
  title,
  to,
}: SubNavBarItemProps) => {
  const [customTrigger, setCustomTrigger] = useState<boolean>(false);
  const { location } = useLocation();
  const toWithCompany = `${
    requiresClient &&
    !R.defaultTo(location.pathname.slice(1), to).includes(R.defaultTo("", company)) &&
    to
      ? `${company}/`
      : ""
  }${to ? to : location.pathname.slice(1)}`;
  const toAdjusted =
    (!requiresClient || (requiresClient && company)) && to
      ? toWithCompany
      : location.pathname.slice(1);

  const linkOnClick = e => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    if (onClick) {
      onClick();
    }
    if (requiresClient && !company) {
      setCustomTrigger(true);
    }
  };

  useEffect(() => {
    if (cancel || company) {
      setCustomTrigger(false);
    }
  }, [cancel, company]);

  return (
    <OverlayTrigger
      customTrigger={customTrigger}
      exitOnBackgroundClick={true}
      loseFocusOnScroll={false}
      onExit={() => setCustomTrigger(false)}
      overlay={overlayItem}
      placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
    >
      <Link className="subNavBarItem" onClick={linkOnClick} to={toAdjusted}>
        {title}
      </Link>
    </OverlayTrigger>
  );
};

export const NavBar: React.FC = () => {
  const { location, company: urlCompany } = useLocation();
  const [activeMainNavBarIcon, setActiveMainNavBarIcon] = useState<string>("");
  const [activeSubPage, setActiveSubPage] = useState<string>("");
  const [company, setCompanyRaw] = useState<string>(urlCompany);
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>();
  const [companyInfoMap, setCompanyInfoMap] = useState<Record<string, CompanyInfo>>({});
  const [companySearchQuery, setCompanySearchQuery] = useState<string>("");
  const [expanded, setExpandedRaw] = useState(false);
  const [hideResults, setHideResults] = useState(false);
  const [initialPageCompanyLoaded, setInitialPageCompanyLoaded] = useState<boolean>(false);
  const [newCompanySelector, setNewCompanySelector] = useState<boolean>();
  const [pageToTravelTo, setPageToTravelToRaw] = useState<string>("");
  const [searchFocusSetter, setSearchFocusSetter] = useState<boolean>(false);
  const [selectedChannel, setSelectedChannel] = useState<string>("");
  const [selectedDropdown, setSelectedDropdown] = useState<string>("");
  const [subNavBarExpanded, setSubNavBarExpandedRaw] = useState(false);
  const allUserCompanies = useSelector(UserRedux.companiesSelector);
  const experimentContext = useExperimentContext();
  const isInternal = useSelector(UserRedux.isInternalSelector);
  const setPageToTravelTo = useCallback((page: string) => {
    setPageToTravelToRaw(page);
  }, []);
  const experiments: string[] = useMemo(() => {
    return R.filter(key => experimentContext[key] === true, R.keys(experimentContext));
  }, [experimentContext]);
  const mediaTypes: string[] = useMemo(
    () => (companyInfo && companyInfo.media_types ? companyInfo.media_types : []),
    [companyInfo]
  );
  const customDashboards: CustomDashboardInfo[] = useMemo(
    () => (companyInfo && companyInfo.custom_dashboards ? companyInfo.custom_dashboards : []),
    [companyInfo]
  );
  const role = useSelector(UserRedux.roleSelector);
  const setError = useSetError();
  const LOCATIONS = useMemo(() => removeDisabledRoutes(experiments, role), [experiments, role]);
  const FLAT_ROUTES = useMemo(() => flattenRoutes(LOCATIONS), [LOCATIONS]);
  const companies: { company: string; role: number }[] = useMemo(
    () =>
      R.filter(
        company => !TEST_COMPANIES.includes(company.company),
        R.defaultTo([], allUserCompanies)
      ),
    [allUserCompanies]
  );

  const setCompany = useCallback(
    (company: string) => {
      setCompanyRaw(company);
      if (companyInfoMap[company]) {
        setCompanyInfo(companyInfoMap[company]);
      } else if (company) {
        (async () => {
          try {
            const res = await UsersLambdaFetch("/company", { params: { company } });
            const info = await awaitJSON(res);
            setCompanyInfo(info);
            setCompanyInfoMap(map => ({ ...map, [company]: info }));
          } catch (e) {
            const reportError = e as Error;
            setError({
              title: "Error",
              message: `Error fetching company info ${reportError.message}`,
              reportError,
            });
          }
        })();
      }
    },
    [companyInfoMap, setError]
  );

  const userHasOnlyOneClient = useMemo(() => R.defaultTo([], companies).length === 1, [companies]);

  useEffect(() => {
    if (urlCompany && !initialPageCompanyLoaded && R.isNil(companyInfo)) {
      setCompany(urlCompany);
      setInitialPageCompanyLoaded(true);
    }
  }, [company, companyInfo, initialPageCompanyLoaded, setCompany, urlCompany]);

  useEffect(() => {
    if (!expanded) {
      setCompany(urlCompany);
    } else if (expanded && userHasOnlyOneClient) {
      setCompany(companies[0].company);
    }
  }, [companies, expanded, setCompany, urlCompany, userHasOnlyOneClient]);

  const setExpanded = useCallback(
    expanded => {
      setExpandedRaw(expanded);
    },
    [setExpandedRaw]
  );

  const setSubNavBarExpanded = useCallback(
    expanded => {
      if (isInternal) {
        setSubNavBarExpandedRaw(expanded);
      }
    },
    [isInternal]
  );

  const logoSrc = `https://cdn.blisspointmedia.com/logos/${
    expanded ? "logo_dark.svg" : "square_logo_dark.png"
  }`;

  const [pageSearchText, setPageSearchText] = useState<string>("");

  const header = (title: string, subNavBar?: boolean) => {
    return <div className={subNavBar ? "subNavBarHeader" : "navBarHeader"}>{title}</div>;
  };

  const searchResults = useCallback(
    (searchQuery: string) => {
      const filteredRoutes = R.filter((route: string) => {
        return (
          !R.isNil(searchQuery) &&
          searchQuery.length > 0 &&
          route.toLowerCase().includes(searchQuery.toLowerCase()) &&
          (R.isNil(FLAT_ROUTES[route].experiment) ||
            experiments.includes(FLAT_ROUTES[route].experiment)) &&
          (!FLAT_ROUTES[route].requiresClient || (company && FLAT_ROUTES[route].requiresClient))
        );
      }, R.keys(FLAT_ROUTES));
      return (
        <div className="searchResults" id="searchResults">
          <div className="searchResultsRows" style={{ cursor: "default" }}>
            {filteredRoutes.length === 0 ? (
              <div className="searchResultsRow">No search results found for "{searchQuery}"</div>
            ) : (
              R.map(
                filteredRoute => (
                  <Link
                    id={uuid.v4()}
                    onClick={() => {
                      setCompanySearchQuery("");
                      if (FLAT_ROUTES[filteredRoute].onClick) {
                        FLAT_ROUTES[filteredRoute].onClick();
                      }
                      if (!company && FLAT_ROUTES[filteredRoute].requiresClient) {
                        setNewCompanySelector(true);
                      }
                    }}
                    to={
                      FLAT_ROUTES[filteredRoute].to
                        ? `${FLAT_ROUTES[filteredRoute].requiresClient ? `${company}/` : ""}${
                            FLAT_ROUTES[filteredRoute].to
                          }`
                        : location.pathname.slice(1)
                    }
                  >
                    <div className="searchResultsRow">
                      {`${filteredRoute
                        .replace("/subPages", "")
                        .replace("/Client Buying", "")
                        .replace("/Client Operations", "")
                        .replace("/Client Planning Tools", "")
                        .replace(/\//g, " / ")}`}
                    </div>
                  </Link>
                ),
                filteredRoutes
              )
            )}
          </div>
        </div>
      );
    },
    [FLAT_ROUTES, company, experiments, location.pathname]
  );

  useEffect(() => {
    const listener = e => {
      const searchResults: Element = R.defaultTo(
        <div></div>,
        document.getElementById("searchResults") as any
      );
      const searchContainer: Element = R.defaultTo(
        <div></div>,
        document.getElementById("pageSearchInput") as any
      );
      const searchContainerIcon: Element = R.defaultTo(
        <div></div>,
        document.getElementById("pageSearchIconContainer") as any
      );
      if (
        searchResults &&
        searchContainer &&
        searchContainerIcon &&
        searchResults.contains &&
        searchContainer.contains &&
        searchContainerIcon.contains &&
        e &&
        e.target &&
        !searchResults.contains(e.target as any) &&
        !searchContainer.contains(e.target as any) &&
        !searchContainerIcon.contains(e.target as any)
      ) {
        setHideResults(true);
      } else {
        setHideResults(false);
      }
    };
    if (!hideResults) {
      // Detect if we exited the pages search bar
      window.addEventListener("click", listener);
      return () => {
        window.removeEventListener("click", listener);
      };
    }
  }, [hideResults]);

  const filteredCompanies = useMemo(
    () =>
      R.filter(
        (company: any) => company.company.includes(companySearchQuery.trim().toLowerCase()),
        companies
      ),
    [companies, companySearchQuery]
  );
  const availableChannels = useMemo(
    () =>
      R.filter(
        channel =>
          mediaTypes.includes(CHANNELS_MAP[channel]) ||
          !company ||
          (Boolean(company) &&
            experiments.includes("notDefaultHomeV2") &&
            (channel === "Cross-Channel" || channel === "TVAD Cross-Channel")) || // Remove cross-channel if we're on a client page and they're defaulting to Home V2
          (channel === "Custom" && Boolean(customDashboards.length)) ||
          (channel === "Search & Shopping" &&
            (role === 0 || experiments.includes("enableSearchShopping")) &&
            (mediaTypes.includes("search") || mediaTypes.includes("shopping"))),
        CHANNELS_LINKS
      ) as string[],
    [mediaTypes, company, experiments, customDashboards, role]
  );

  const isTVAD = useMemo(() => {
    return (
      availableChannels.includes("Streaming TV") ||
      availableChannels.includes("Linear TV") ||
      availableChannels.includes("Audio") ||
      availableChannels.includes("Display")
    );
  }, [availableChannels]);

  const closeNavBar = useCallback(() => {
    setCompanyInfo(undefined);
    setCompanySearchQuery("");
    setExpanded(false);
    setInitialPageCompanyLoaded(false);
    setNewCompanySelector(false);
    setPageToTravelTo("");
    setSelectedChannel("");
    setSelectedDropdown("");
    setSubNavBarExpanded(false);
  }, [setExpanded, setPageToTravelTo, setSubNavBarExpanded]);

  const companySelector = (
    <div className="companySelector" id="companySelector">
      {companies && companies.length > 7 && (
        <div className="companySelectorSearchBar">
          <div
            className="pageSearchIcon"
            onClick={() => {
              while (expanded === false) {
                const searchBar = document.getElementById("companySearchInput");
                if (searchBar) {
                  searchBar.focus();
                }
              }
              const searchBar = document.getElementById("companySearchInput");
              if (searchBar) {
                searchBar.focus();
              }
            }}
          >
            <MdSearch className="pageSearchIcon" />
          </div>
          <Form.Control
            className="companySearchInput"
            id="companySearchInput"
            onChange={e => {
              setCompanySearchQuery(e.target.value);
            }}
            placeholder="Search Clients"
            value={companySearchQuery}
          />
        </div>
      )}
      <div className="companySelectorTitle">Pick a client</div>
      <div className="companySelectorRows">
        {filteredCompanies.length === 0 ? (
          <div className="companySelectorRow" style={{ cursor: "default", height: "64px" }}>
            No search results found for "{companySearchQuery}"
          </div>
        ) : (
          R.map(
            (companyRow: any) =>
              (company && location.pathname.includes(company)) || pageToTravelTo ? (
                <Link
                  key={companyRow.company}
                  className="companySelectorRow"
                  onClick={() => {
                    closeNavBar();
                    setCompany(companyRow.company);
                    setCompanySearchQuery("");
                  }}
                  to={
                    pageToTravelTo
                      ? pageToTravelTo.replace("{company}", companyRow.company)
                      : location.pathname.replace(company, companyRow.company)
                  }
                >
                  {`${companyRow.company}`}
                </Link>
              ) : (
                <div
                  id={uuid.v4()}
                  className="companySelectorRow"
                  onClick={() => {
                    setCompany(companyRow.company);
                    setCompanySearchQuery("");
                    setNewCompanySelector(false);
                    setPageToTravelTo("");
                  }}
                >
                  {`${companyRow.company}`}
                </div>
              ),
            filteredCompanies
          )
        )}
      </div>
      <div className="cancelContainer">
        <div className="cancelButton" onClick={() => setNewCompanySelector(false)}>
          Cancel
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (!R.isNil(newCompanySelector) && newCompanySelector === false) {
      setNewCompanySelector(undefined);
      setCompanySearchQuery("");
    }
  }, [newCompanySelector]);

  useEffect(() => {
    if (selectedChannel) {
      setSubNavBarExpanded(true);
    }
  });

  useEffect(() => {
    if (searchFocusSetter) {
      const searchBar = document.getElementById("pageSearchInput");
      if (searchBar) {
        searchBar.focus();
      }
    }
  });

  useEffect(() => {
    if (!expanded) {
      const currentPath = location.pathname.slice(1);
      const filteredPath = company
        ? currentPath.replace(`${company}/`, "").replace(company, "")
        : currentPath;
      const items = filteredPath === "" ? ["Home"] : currentNavBarItem(LOCATIONS, filteredPath);
      setActiveMainNavBarIcon(items && items.length && items[0] ? items[0] : "");
      setActiveSubPage(items && items.length > 2 && items[1] === "subPages" ? items[2] : "");
    }
  }, [LOCATIONS, company, expanded, location.pathname]);

  const mainNavBarItemDropdownRows = (channel: string, company: string, tool: string) => {
    const pages: any[] = [];
    // TODO: Remove me when we move to 2.0
    if (channel && channel !== "Cross-Channel" && channel !== "Custom") {
      for (let key of R.keys(LOCATIONS[channel].subPages)) {
        pages.push({ ...LOCATIONS[channel].subPages[key], title: key });
      }
    } else if (channel && channel === "Custom") {
      for (const obj of customDashboards) {
        pages.push({
          to: `customdashboard/${obj.name.toLowerCase().replace(/ /g, "_")}`,
          requiresClient: true,
          roleRequired: 10,
          title: obj.name,
        });
      }
    } else if (
      tool &&
      !R.keys(LOCATIONS[tool]).includes("to") &&
      !R.keys(LOCATIONS[tool]).includes("onClick")
    ) {
      for (let key of R.keys(LOCATIONS[tool])) {
        pages.push({ ...LOCATIONS[tool][key], title: key });
      }
    } else {
      return undefined;
    }
    return (
      <div
        className="mainNavBarItemDropdownRows"
        style={{ pointerEvents: !company ? "none" : "all" }}
      >
        {R.map(
          page => (
            <Link
              onClick={closeNavBar}
              to={`${page.requiresClient ? `${company}/` : ""}${page.to}`}
              key={uuid.v4()}
            >
              <div
                className={`mainNavBarItemDropdownRow ${
                  activeSubPage === page.title && channel === activeMainNavBarIcon
                    ? "activeSubPage"
                    : ""
                }`}
              >
                {page.title}
              </div>
            </Link>
          ),
          pages
        )}
      </div>
    );
  };

  const filteredInternalHeaders: string[] = useMemo(
    () =>
      R.filter(
        elem => Boolean(elem),
        R.map(
          header => (R.keys(LOCATIONS["Internal Tools"]).includes(header) ? header : ""),
          INTERNAL_TOOLS_HEADERS
        )
      ) as string[],
    [LOCATIONS]
  );

  const internalToolsVisible = isInternal && R.keys(LOCATIONS["Internal Tools"]).length > 0;

  const showOriginalHome = experiments.includes("notDefaultHomeV2") || !company;
  let companyHomeIconTo = experiments.includes("notDefaultHomeV2")
    ? "home"
    : "cross-channel/overview";

  const homeIcon = (
    <>
      <MainNavBarItem
        active={activeMainNavBarIcon === (showOriginalHome ? "Home" : "Cross-Channel")}
        cancel={newCompanySelector === false}
        company={company}
        expanded={expanded}
        icon={showOriginalHome ? <MdHomeFilled /> : CHANNEL_ICONS["Cross-Channel"]}
        onClick={e => {
          closeNavBar();
        }}
        opensSubNavBar={false}
        title={showOriginalHome ? "Home" : "Cross-Channel"}
        to={company ? `${company}/${companyHomeIconTo}` : "/companies"}
      />
      {isTVAD && !showOriginalHome && (
        <MainNavBarItem
          active={activeMainNavBarIcon === "TVAD Cross-Channel"}
          cancel={newCompanySelector === false}
          company={company}
          expanded={expanded}
          icon={CHANNEL_ICONS["TVAD Cross-Channel"]}
          onClick={e => {
            closeNavBar();
          }}
          opensSubNavBar={false}
          title={"TVAD Cross-Channel"}
          to={company ? `${company}/tvad-cross-channel` : "/companies"}
        />
      )}
    </>
  );

  const mainNavBar = (
    <div className={`bpm-navbar-inner-container ${expanded ? "Expanded" : "Collapsed"}`}>
      <div className="bpmNavBarItemContainer">
        <div className={`mainNavBarExpander ${expanded ? "Expanded" : ""}`}>
          <OverlayTrigger
            customTrigger={R.defaultTo(false, newCompanySelector)}
            exitOnBackgroundClick={true}
            loseFocusOnScroll={false}
            onExit={() => {
              setNewCompanySelector(undefined);
            }}
            overlay={companySelector}
            placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
          >
            <div
              className="logoDropdownContainer"
              onClick={() => {
                if ((expanded || !company) && !userHasOnlyOneClient) {
                  setPageToTravelTo("");
                  setNewCompanySelector(true);
                }
              }}
              style={{ cursor: userHasOnlyOneClient ? "default" : "pointer" }}
            >
              {expanded && !company ? (
                <div className="noClient">Select Client</div>
              ) : (
                <div className="logoContainer">
                  {company ? (
                    <Img
                      className="logo"
                      alt={company}
                      src={
                        company
                          ? `https://cdn.blisspointmedia.com/companies/${
                              company.includes("tinuitidemo") ||
                              company === "tinuititest" ||
                              company === TINUITI_TEST_HOME_SERVICES
                                ? "tinuitidemo"
                                : company
                            }/square_logo_dark.png`
                          : logoSrc
                      }
                      unloader={<span className="noLogoText">{company}</span>}
                    />
                  ) : (
                    <OverlayTrigger
                      placement={OverlayTrigger.PLACEMENTS.RIGHT.CENTER}
                      overlay={<Tooltip id={uuid.v4()}>Pick a client</Tooltip>}
                    >
                      {<MdKeyboardArrowDown className="noClientIcon" />}
                    </OverlayTrigger>
                  )}
                </div>
              )}
              {expanded && (
                <OverlayTrigger
                  placement={OverlayTrigger.PLACEMENTS.RIGHT.CENTER}
                  overlay={
                    userHasOnlyOneClient || !R.defaultTo("", company).length ? (
                      <div></div>
                    ) : (
                      <Tooltip id={uuid.v4()}>Pick a client</Tooltip>
                    )
                  }
                >
                  <div
                    className="companySelectArrowContainer"
                    style={{ opacity: userHasOnlyOneClient ? 0 : 1 }}
                  >
                    <MdKeyboardArrowDown className="companySelectArrow" />
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </OverlayTrigger>
          <div
            className={`mainNavBarExpanderBlock ${expanded ? "Expanded" : ""}`}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? <MdArrowBackIos /> : <MdArrowForwardIos />}
          </div>
        </div>
        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.RIGHT.CENTER}
          overlay={expanded ? <div></div> : <Tooltip id={uuid.v4()}>Page Search</Tooltip>}
        >
          <div
            className={`navSearchContainer ${expanded ? "Expanded" : ""}`}
            onClick={() => {
              if (!expanded) {
                setHideResults(false);
                setSearchFocusSetter(true);
              }
            }}
            style={{ cursor: expanded ? "default" : "text" }}
          >
            <div
              className="pageSearchIconContainer"
              id="pageSearchIconContainer"
              onClick={() => {
                setHideResults(false);
                setSearchFocusSetter(true);
              }}
            >
              <MdSearch className="pageSearchIcon" />
            </div>

            {expanded && (
              <Form.Control
                className="pageSearchInput"
                id="pageSearchInput"
                onChange={e => {
                  setPageSearchText(e.target.value);
                  setHideResults(false);
                }}
                onFocus={() => {
                  setHideResults(false);
                  setSearchFocusSetter(false);
                }}
                placeholder="Search Pages"
                value={pageSearchText}
              />
            )}
          </div>
        </OverlayTrigger>
        {homeIcon}
        <div className="solid-nav-bar-container">
          <hr className="solid-nav-bar"></hr>
        </div>
        {expanded && header("Channels")}
        {R.map(
          channel => (
            <div id={uuid.v4()} key={channel}>
              <MainNavBarItem
                active={activeSubPage !== "" && channel === activeMainNavBarIcon}
                cancel={newCompanySelector === false}
                company={company}
                dropdownSelected={selectedDropdown === channel}
                expanded={expanded}
                hasDropdownItems={
                  channel !== "Cross-Channel" && channel !== "TVAD Cross-Channel" && !isInternal
                }
                icon={CHANNEL_ICONS[channel]}
                onClick={() => {
                  // TODO: Remove me when we move to 2.0, replace this with a link to the main
                  // channel page
                  if (
                    channel === selectedDropdown ||
                    channel === "Cross-Channel" ||
                    channel === "TVAD Cross-Channel"
                  ) {
                    setSelectedDropdown("");
                  } else {
                    setSelectedChannel("");
                    setSelectedDropdown(channel);
                    setSubNavBarExpanded(false);
                  }
                  if (
                    company &&
                    (channel === "Cross-Channel" || channel === "TVAD Cross-Channel")
                  ) {
                    closeNavBar();
                  } else if (!company && channel === "Cross-Channel") {
                    setPageToTravelTo(`{company}/${LOCATIONS["Cross-Channel"].to}`);
                  } else if (!company && channel === "TVAD Cross-Channel") {
                    setPageToTravelTo(`{company}/${LOCATIONS["TVAD Cross-Channel"].to}`);
                  }
                }}
                opensSubNavBar={
                  !["Cross-Channel", "TVAD Cross-Channel"].includes(channel) && isInternal
                }
                opensSubNavBarOnClick={() => {
                  if (channel === selectedChannel) {
                    setSelectedChannel("");
                    setSubNavBarExpanded(false);
                  } else {
                    setSelectedChannel(channel);
                    setSelectedDropdown("");
                    setSubNavBarExpanded(true);
                  }
                }}
                overlayItem={companySelector}
                requiresClient={true}
                selected={selectedChannel === channel}
                title={channel}
                to={
                  channel === "TVAD Cross-Channel"
                    ? LOCATIONS["TVAD Cross-Channel"].to
                    : channel === "Cross-Channel"
                    ? LOCATIONS["Cross-Channel"].to
                    : ""
                }
              />
              {/* TODO: Remove me when we move to 2.0, replace this with a link to the main
             channel page */}
              {expanded &&
                selectedDropdown === channel &&
                company &&
                mainNavBarItemDropdownRows(selectedDropdown, company, "")}
              {/* ////////////////////////////// */}
            </div>
          ),
          availableChannels
        )}
        <div className="solid-nav-bar-container">
          <hr className="solid-nav-bar"></hr>
        </div>
        {expanded && header("Tools")}
        {R.map(
          tool => (
            <div id={uuid.v4()} key={tool}>
              <MainNavBarItem
                active={tool === activeMainNavBarIcon}
                cancel={newCompanySelector === false}
                company={company}
                dropdownSelected={selectedDropdown === tool}
                expanded={expanded}
                icon={LOCATIONS[tool].icon || TOOLS_ICONS[tool]}
                hasDropdownItems={R.isNil(LOCATIONS[tool].to)}
                onClick={() => {
                  if (LOCATIONS[tool].to) {
                    if (!LOCATIONS[tool].requiresClient) {
                      setCompany("");
                    } else if (company) {
                      closeNavBar();
                    } else {
                      setPageToTravelTo(`{company}/${LOCATIONS[tool].to}`);
                    }
                  }
                  setSelectedChannel("");
                  setSubNavBarExpanded(false);
                  if (TOOLS_LINKS.includes(tool)) {
                    setSelectedDropdown(tool !== selectedDropdown ? tool : "");
                  }
                }}
                opensSubNavBar={false}
                overlayItem={companySelector}
                requiresClient={true}
                selected={selectedChannel === tool}
                title={tool}
                to={LOCATIONS[tool].to}
              />
              {expanded &&
                selectedDropdown === tool &&
                company &&
                mainNavBarItemDropdownRows("", company, selectedDropdown)}
            </div>
          ),
          R.filter(
            tool =>
              (isInternal ||
                ["Creatives", "Competitive", "Customer Insights", "Brand Health", "MMM"].includes(
                  tool
                )) &&
              R.keys(LOCATIONS).includes(tool),
            ALL_EXTERNAL_TOOLS_LINKS
          ) as string[]
        )}
        {internalToolsVisible && (
          <div className="solid-nav-bar-container">
            <hr className="solid-nav-bar"></hr>
          </div>
        )}
        {expanded && internalToolsVisible && header("Internal-only")}
        {internalToolsVisible && (
          <>
            <MainNavBarItem
              active={activeMainNavBarIcon === "Internal Tools"}
              cancel={newCompanySelector === false}
              company={company}
              expanded={expanded}
              icon={LOCATIONS["Internal-only"]["Internal Tools"].icon}
              onClick={e => {
                setSelectedDropdown("");
                setSubNavBarExpanded(selectedChannel !== "Internal Tools");
                setSelectedChannel(selectedChannel === "Internal Tools" ? "" : "Internal Tools");
              }}
              opensSubNavBar={false}
              selected={selectedChannel === "Internal Tools"}
              title={"Internal Tools"}
              to={location.pathname.slice(1)}
            />
            <MainNavBarItem
              active={activeMainNavBarIcon === "Incrementality Playbook"}
              cancel={newCompanySelector === false}
              company={company}
              expanded={expanded}
              icon={LOCATIONS["Internal-only"]["Incrementality Playbook"].icon}
              onClick={e => {
                setCompany("");
                closeNavBar();
              }}
              opensSubNavBar={false}
              selected={selectedChannel === "Incrementality Playbook"}
              title={"Incrementality Playbook"}
              to={LOCATIONS["Incrementality Playbook"].to}
            />
          </>
        )}
        <div className="solid-nav-bar-container">
          <hr className="solid-nav-bar"></hr>
        </div>
        <MainNavBarItem
          active={activeMainNavBarIcon === "Reference Docs"}
          cancel={newCompanySelector === false}
          company={company}
          expanded={expanded}
          icon={<MdDocumentScanner />}
          onClick={() => {
            setCompany("");
            closeNavBar();
          }}
          opensSubNavBar={false}
          overlayItem={companySelector}
          requiresClient={R.defaultTo("", company).length > 1}
          title={"Reference Docs"}
          to={LOCATIONS["Reference Docs"].to}
        />
        <MainNavBarItem
          active={activeMainNavBarIcon === "Log Out"}
          cancel={newCompanySelector === false}
          company={company}
          expanded={expanded}
          icon={<MdLogout />}
          onClick={() => {
            closeNavBar();
            LOCATIONS["Log Out"].onClick();
          }}
          opensSubNavBar={false}
          title={"Log Out"}
          to={location.pathname.slice(1)}
        />
      </div>
      <Link to={company ? `/${company}` : "/companies"} onClick={closeNavBar}>
        <div className={`logoContainer footer ${expanded ? "Expanded" : ""}`}>
          <Img alt={"Bliss Point"} src={logoSrc} />
        </div>
      </Link>
    </div>
  );

  const subNavBar = expanded && subNavBarExpanded && (
    <div className="bpm-sub-navbar-inner-container" onClick={() => setHideResults(true)}>
      <div className="bpmNavBarItemContainer">
        <div className="closeSubNavBar">
          <div className="closeSubNavBarTitle"> INTERNAL-ONLY</div>
          <div
            className="closeSubNavBarIcon"
            onClick={() => {
              setSubNavBarExpanded(false);
              setSelectedChannel("");
            }}
          >
            <MdClose />
          </div>
        </div>
        {R.map(
          section => {
            const headers =
              selectedChannel === "Internal Tools"
                ? filteredInternalHeaders
                : CLIENT_TOOLS_HEADERS.filter(
                    header => getChannelTools(LOCATIONS, selectedChannel, header).length
                  );
            const tools =
              selectedChannel === "Internal Tools"
                ? getInternalTools(LOCATIONS, section)
                : getChannelTools(LOCATIONS, selectedChannel, section);

            return (
              Boolean(tools.length) && (
                <div>
                  {section !== headers[0] && (
                    <div className="solid-nav-bar-container sub">
                      <hr className="solid-sub-nav-bar"></hr>
                    </div>
                  )}
                  {header(section, true)}
                  {R.map(
                    tool => (
                      <SubNavBarItem
                        cancel={newCompanySelector === false}
                        company={company}
                        onClick={() => {
                          if (!tool.requiresClient) {
                            closeNavBar();
                            setCompany("");
                          } else if (company) {
                            closeNavBar();
                          } else {
                            setPageToTravelTo(`{company}/${tool.to}`);
                          }
                        }}
                        overlayItem={companySelector}
                        requiresClient={tool.requiresClient}
                        title={tool.title}
                        to={tool.to}
                      />
                    ),
                    tools
                  )}
                </div>
              )
            );
          },
          selectedChannel === "Internal Tools" ? filteredInternalHeaders : CLIENT_TOOLS_HEADERS
        )}
      </div>
      {selectedChannel !== "Internal Tools" && (
        <div
          className="bpm-sub-navbar-shortcuts-container"
          onClick={() => {
            setSelectedChannel("Internal Tools");
          }}
        >
          {selectedChannel !== "Internal Tools" && header("Shortcut", true)}
          <div className="shortcuts">
            <AllBackendToolsIcon />
            <div className="shortcutsLabel">{"Internal Tools"}</div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div>
      {expanded && (
        <div
          className="bpm-navbar-overlay"
          onClick={() => {
            closeNavBar();
          }}
        />
      )}
      {expanded &&
        pageSearchText &&
        pageSearchText.length > 0 &&
        !hideResults &&
        searchResults(pageSearchText.trim())}
      <div
        className={`bpm-navbar ${expanded ? "Expanded" : "Collapsed"}`}
        onClick={() => {
          if (!expanded) {
            setExpanded(true);
            setSubNavBarExpanded(false);
          }
        }}
      >
        {mainNavBar}
        {subNavBar}
      </div>
    </div>
  );
};

export default NavBar;
