import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useSetError } from "../../redux/modals";
import MetaBuyingAd from "./MetaBuyingAd";
import MetaBuyingAdSet from "./MetaBuyingAdSet";
import MetaBuyingCampaign from "./MetaBuyingCampaign";
import MetaBuyingCreationPrompt from "./MetaBuyingCreationPrompt";
import { CampaignRow, AdSetRow, CreationTab } from "@blisspointmedia/bpm-types/dist/MetaBuying";
import * as R from "ramda";
import "./MetaBuyingCreate.scss";
import MetaBuyingReviewModal from "./MetaBuyingReviewModal";
import { getCreationTabs, generateFormattedDate, removeIsoTZ } from "../MetaBuyingUtils";
import { useSelector } from "react-redux";
import * as UserRedux from "../../redux/user";
import { PageTab } from "../MetaBuying";
import { metaBuyingFormDefault } from "../MetaBuyingContext";
import {
  TAB_CAMPAIGN,
  TAB_AD_SET,
  TAB_AD,
  CAMPAIGN,
  AD_SET,
  AD,
  CAMPAIGN_DAILY_BUDGET,
  CAMPAIGN_LIFETIME_BUDGET,
  CLIENT,
  AGENCY,
  CURRENT_DATE,
  OFFSITE_CONVERSIONS,
  VALUE,
  AD_SET_LIFETIME_BUDGET,
  AD_SET_DAILY_BUDGET,
  BID_STRATEGIES,
  COST_CAP,
  LOWEST_COST_WITH_BID_CAP,
  LOWEST_COST_WITH_MIN_ROAS,
} from "../MetaBuyingConstants";
import * as Dfns from "date-fns/fp";

const TAB_LEVEL_MAP = {
  [CAMPAIGN]: TAB_CAMPAIGN,
  [AD_SET]: TAB_AD_SET,
  [AD]: TAB_AD,
};

const REQUIRED_FIELDS_CAMPAIGN = {
  objective: "Campaign Objective",
  budget_type: "Budget Type",
};

const REQUIRED_FIELDS_AD_SET = {
  optimization_goal: "Optimization Goal",
};

const REQUIRED_FIELDS_AD = {
  creative_asset: "Creative Asset",
  facebook_page_id: "Facebook Page",
};

interface MetaBuyingCreateProps {
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  setSelectedAdAccount: React.Dispatch<
    React.SetStateAction<{
      account_id: string;
      account_name: string;
      business_manager: string;
    }>
  >;
  adAccountOptions: {
    account_id: string;
    account_name: string;
    business_manager: string;
  }[];
  namingConventions: {
    [CAMPAIGN]: number[];
    [AD_SET]: number[];
    [AD]: number[];
  };
  client: string;
  granularity: string;
  dimensions: Record<string, any>;
  selectedNav?: string;
  path?: string;
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  creationTabSelections: {
    [TAB_CAMPAIGN]: boolean;
    [TAB_AD_SET]: boolean;
    [TAB_AD]: boolean;
  };
  setCreationTabSelections: React.Dispatch<
    React.SetStateAction<{
      [TAB_CAMPAIGN]: boolean;
      [TAB_AD_SET]: boolean;
      [TAB_AD]: boolean;
    }>
  >;
  selectionsSubmitted: boolean;
  setSelectionsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  campaigns: CampaignRow[];
  adSets: AdSetRow[];
  customAudiences: { custom_audience_id: string; name: string }[];
  pixels: { pixel_id: string; name: string }[];
  facebookPages: { page_id: string; name: string }[];
  // instagramAccounts: { id: string; username: string }[];
  goToTables: (tab: PageTab) => void;
}

export const MetaBuyingCreate = ({
  selectedAdAccount,
  setSelectedAdAccount,
  adAccountOptions,
  namingConventions,
  client,
  granularity,
  dimensions,
  tabIndex,
  setTabIndex,
  creationTabSelections,
  setCreationTabSelections,
  selectionsSubmitted,
  setSelectionsSubmitted,
  campaigns,
  adSets,
  customAudiences,
  pixels,
  facebookPages,
  // instagramAccounts,
  goToTables,
}: RouteComponentProps & MetaBuyingCreateProps): JSX.Element => {
  const setError = useSetError();

  const creationTabs = getCreationTabs(creationTabSelections);

  const email = useSelector(UserRedux.emailSelector);

  const [showReviewModal, setShowReviewModal] = useState(false);
  const [allNameFields, setAllNameFields] = useState<Record<string, any>>(() => {
    let fields = {};
    const { campaign, ad_group, ad } = namingConventions;
    const originCampaign = R.intersection(campaign, R.union(ad, ad_group));
    const originAdSet = R.filter(
      element => !R.includes(element, originCampaign),
      R.intersection(ad_group, ad)
    );
    R.keys(dimensions).forEach(dimensionId => {
      const dimension = dimensions[dimensionId];
      const dimensionType = dimension.type;

      const { business_manager } = selectedAdAccount;

      let value = "";

      if (client && dimensionType === CLIENT) {
        value = client;
      } else if (business_manager && dimensionType === AGENCY) {
        value = business_manager;
      } else if (dimensionType === CURRENT_DATE) {
        value = generateFormattedDate(dimension.date_format);
      }

      let field: { value: string; inheritedFrom?: string } = {
        value,
      };
      if (R.includes(Number(dimensionId), originCampaign)) {
        field.inheritedFrom = "campaign";
      } else if (R.includes(Number(dimensionId), originAdSet)) {
        field.inheritedFrom = "adset";
      }
      fields[dimensionId] = field;
    });

    return fields;
  });

  const saveNameFields = (nameFields: Record<string, any>): void => {
    let newAllNameFields = R.clone(allNameFields);
    R.keys(nameFields).forEach(dimensionId => {
      let field = R.clone(allNameFields[dimensionId]);
      field.value = nameFields[dimensionId];
      newAllNameFields[dimensionId] = field;
    });
    setAllNameFields(newAllNameFields);
  };

  const validateForms = (
    metaBuyingForm: typeof metaBuyingFormDefault,
    navigate: () => void,
    nameFields: Record<string, any>,
    tabToValidate?: CreationTab
  ): void => {
    const metaCampaignForm = metaBuyingForm.campaign;
    const metaAdSetForm = metaBuyingForm.ad_set;
    const metaAdForm = metaBuyingForm.ad;
    const tabsToValidate = tabToValidate ? [tabToValidate] : creationTabs;

    let newAllNameFields = R.clone(allNameFields);

    R.keys(nameFields).forEach(dimensionId => {
      let field = R.clone(allNameFields[dimensionId]);
      field.value = nameFields[dimensionId];
      newAllNameFields[dimensionId] = field;
    });

    let errorLevel = "";

    try {
      if (R.includes(TAB_CAMPAIGN, tabsToValidate)) {
        errorLevel = TAB_CAMPAIGN;
        namingConventions[CAMPAIGN].forEach(dimensionId => {
          if (R.isEmpty(newAllNameFields[dimensionId].value)) {
            throw new Error(`Missing required field '${dimensions[dimensionId].name}'`);
          }
        });

        if (
          R.includes(metaCampaignForm.budget_type, [
            CAMPAIGN_LIFETIME_BUDGET,
            CAMPAIGN_DAILY_BUDGET,
          ])
        ) {
          if (R.isNil(metaCampaignForm.bid_strategy)) {
            throw new Error("Missing required field 'Bid Strategy'");
          }
          if (R.isNil(metaCampaignForm.budget)) {
            throw new Error("Campaign budget not set");
          } else if (metaCampaignForm.budget < 100) {
            throw new Error("Campaign budget must be greater than $1.00");
          }
        }

        Object.entries(REQUIRED_FIELDS_CAMPAIGN).forEach(([field, label]) => {
          if (R.isNil(metaCampaignForm[field])) {
            throw new Error(`Missing required field '${label}'`);
          }
        });
      }

      if (R.includes(TAB_AD_SET, tabsToValidate)) {
        errorLevel = TAB_AD_SET;
        namingConventions[AD_SET].forEach(dimensionId => {
          if (R.isEmpty(newAllNameFields[dimensionId].value)) {
            throw new Error(`Missing required field '${dimensions[dimensionId].name}'`);
          }
        });

        let now = new Date();
        let isoStart: Date | null = null;
        let isoEnd: Date | null = null;

        if (metaAdSetForm.start_time && metaAdSetForm.end_time) {
          isoStart = Dfns.parseISO(removeIsoTZ(metaAdSetForm.start_time));
          isoEnd = Dfns.parseISO(removeIsoTZ(metaAdSetForm.end_time));

          if (Dfns.isAfter(isoStart, now)) {
            isoStart = now;
          }

          if (Dfns.differenceInMilliseconds(isoStart, isoEnd) < 1800000) {
            throw new Error("Budget start and end times must be at least 30 minutes apart");
          }
        }

        if (R.isNil(metaAdSetForm.budget) && R.isNil(metaCampaignForm.budget)) {
          throw new Error("Budget must be set at either the campaign or ad set level");
        }

        if (R.includes(metaAdSetForm.budget_type, [AD_SET_LIFETIME_BUDGET, AD_SET_DAILY_BUDGET])) {
          if (R.isNil(metaAdSetForm.bid_strategy)) {
            throw new Error("Missing required field 'Bid Strategy'");
          }
          if (R.isNil(metaAdSetForm.budget)) {
            throw new Error("Ad set budget not set");
          } else if (metaAdSetForm.budget < 100) {
            throw new Error("Ad set budget must be greater than $1.00");
          }
          if (
            (metaAdSetForm.budget_type === AD_SET_DAILY_BUDGET ||
              metaCampaignForm.budget_type === CAMPAIGN_DAILY_BUDGET) &&
            isoStart &&
            isoEnd &&
            Dfns.differenceInMilliseconds(isoStart, isoEnd) <= 86400000
          ) {
            throw new Error(
              "Ad set budget start and end times must be at least 24 hours apart when using daily budget"
            );
          }
        }

        if (
          ((metaAdSetForm.bid_strategy === COST_CAP ||
            metaAdSetForm.bid_strategy === LOWEST_COST_WITH_BID_CAP) &&
            !metaAdSetForm.bid_amount) ||
          (metaAdSetForm.bid_strategy === LOWEST_COST_WITH_MIN_ROAS &&
            (R.isEmpty(metaAdSetForm.bid_constraints) ||
              !metaAdSetForm.bid_constraints.roas_average_floor))
        ) {
          throw new Error(
            `${BID_STRATEGIES[metaAdSetForm.bid_strategy].label} must be set for this bid strategy`
          );
        }

        let requiredAdSetFields: Record<string, any> = {
          ...REQUIRED_FIELDS_AD_SET,
        };

        if (
          R.isEmpty([
            ...metaAdSetForm.instagram_positions,
            ...metaAdSetForm.facebook_positions,
            ...metaAdSetForm.audience_network_positions,
            ...metaAdSetForm.messenger_positions,
          ])
        ) {
          throw new Error("No placements selected");
        }

        if (R.includes(metaAdSetForm.optimization_goal, [OFFSITE_CONVERSIONS, VALUE])) {
          requiredAdSetFields.pixel = "Pixel";
          requiredAdSetFields.conversion_event = "Conversion Event";
        }

        Object.entries(requiredAdSetFields).forEach(([field, label]) => {
          if (R.isNil(metaAdSetForm[field])) {
            throw new Error(`Missing required field '${label}'`);
          }
        });
      }

      if (R.includes(TAB_AD, tabsToValidate)) {
        errorLevel = TAB_AD;

        /*
         * Ignore name check if ad is being edited
         */
        if (metaAdForm.ad_id === null) {
          namingConventions[AD].forEach(dimensionId => {
            if (R.isEmpty(newAllNameFields[dimensionId].value)) {
              throw new Error(`Missing required field '${dimensions[dimensionId].name}'`);
            }
          });
        }

        const urlStringPattern = /^(http|https|ftp):\/\/[^\s"]+$/i;
        if (!urlStringPattern.test(metaAdForm.url)) {
          throw new Error("Invalid URL");
        }

        /*
         * TODO: Validate URL params by checking for TINSEGID
         */
        const queryStringPattern = /^\?([\w-]+(=[\w-{}.]*)?(&[\w-]+(=[\w-{}.]*)?)*)?$/i;
        if (metaAdForm.url_params.length && !queryStringPattern.test(metaAdForm.url_params)) {
          throw new Error("Invalid URL params");
        }

        Object.entries(REQUIRED_FIELDS_AD).forEach(([field, label]) => {
          if (R.isNil(metaAdForm[field])) {
            throw new Error(`Missing required field '${label}'`);
          }
        });
      }

      setAllNameFields(newAllNameFields);
      navigate();
    } catch (e) {
      const error = e as Error;
      setError({
        title: `${errorLevel.length ? `${errorLevel} ` : ""}Validation Error`,
        message: error.message,
      });
    }
  };

  const formattedDimensions = R.keys(namingConventions).reduce(
    (tabMap: Record<string, any>, tab) => {
      let labeledDimensions = {};
      namingConventions[tab].forEach(dimensionId => {
        labeledDimensions[dimensions[dimensionId].name] = allNameFields[dimensionId].value;
      });
      tabMap[TAB_LEVEL_MAP[tab]] = labeledDimensions;
      return tabMap;
    },
    {}
  );

  const selectAdAccount = (adAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  }): void => {
    let newAllNameFields = R.clone(allNameFields);
    const { business_manager } = adAccount;
    R.keys(dimensions).forEach(dimensionId => {
      let { value } = allNameFields[dimensionId];

      const dimension = dimensions[dimensionId];
      const dimensionType = dimension.type;

      if (client && dimensionType === CLIENT) {
        value = client;
      } else if (business_manager && dimensionType === AGENCY) {
        value = business_manager;
      } else if (dimensionType === CURRENT_DATE) {
        value = generateFormattedDate(dimension.date_format);
      }

      newAllNameFields[dimensionId].value = value;
    });

    setAllNameFields(newAllNameFields);
    setSelectedAdAccount(adAccount);
  };

  const CreationTab = (): JSX.Element | null => {
    switch (creationTabs[tabIndex]) {
      case TAB_CAMPAIGN:
        return (
          <MetaBuyingCampaign
            client={client}
            selectedAdAccount={selectedAdAccount}
            namingConvention={namingConventions[CAMPAIGN]}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            showReviewModal={() => setShowReviewModal(true)}
            validateForms={validateForms}
            creationTabs={creationTabs}
            granularity={granularity}
            dimensions={dimensions}
            allNameFields={allNameFields}
            saveNameFields={saveNameFields}
          />
        );
      case TAB_AD_SET:
        return (
          <MetaBuyingAdSet
            client={client}
            selectedAdAccount={selectedAdAccount}
            namingConventions={namingConventions}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            showReviewModal={() => setShowReviewModal(true)}
            validateForms={validateForms}
            creationTabs={creationTabs}
            granularity={granularity}
            dimensions={dimensions}
            allNameFields={allNameFields}
            campaigns={campaigns}
            customAudiences={customAudiences}
            pixels={pixels}
            email={email}
            saveNameFields={saveNameFields}
          />
        );
      case TAB_AD:
        return (
          <MetaBuyingAd
            client={client}
            selectedAdAccount={selectedAdAccount}
            namingConventions={namingConventions}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            showReviewModal={() => setShowReviewModal(true)}
            validateForms={validateForms}
            creationTabs={creationTabs}
            granularity={granularity}
            dimensions={dimensions}
            allNameFields={allNameFields}
            adSets={adSets}
            campaigns={campaigns}
            facebookPages={facebookPages}
            // instagramAccounts={instagramAccounts}
            saveNameFields={saveNameFields}
            goToTables={goToTables}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="metaBuyingCreate">
      {selectionsSubmitted ? (
        <CreationTab />
      ) : (
        <MetaBuyingCreationPrompt
          adAccountOptions={adAccountOptions}
          selectedAdAccount={selectedAdAccount}
          selectAdAccount={selectAdAccount}
          creationTabSelections={creationTabSelections}
          setCreationTabSelections={setCreationTabSelections}
          setSelectionsSubmitted={setSelectionsSubmitted}
        />
      )}
      <MetaBuyingReviewModal
        show={showReviewModal}
        closeModal={() => setShowReviewModal(!showReviewModal)}
        creationTabs={
          Object.keys(creationTabSelections).filter(
            tab => creationTabSelections[tab]
          ) as CreationTab[]
        }
        dimensions={formattedDimensions}
        setTabIndex={setTabIndex}
        selectedAdAccount={selectedAdAccount}
        email={email}
        goToTables={goToTables}
      />
    </div>
  );
};

export default MetaBuyingCreate;
