import { RouteComponentProps } from "@reach/router";
import { Form, DropdownButton, Dropdown, Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "../../Components";
import { filterSegmentationIds, generateFormattedDate } from "../MetaBuyingUtils";
import * as R from "ramda";
import {
  CLIENT,
  AGENCY,
  SEGMENTATION_ID,
  SEGMENT_SELECTION,
  CURRENT_DATE,
} from "../MetaBuyingConstants";

const INHERITED = "INHERITED";

interface MetaBuyingNameFieldsProps {
  client: string;
  agency: string;
  segmentationDimensionId: string;
  dimensions: Record<string, any>;
  nameFields: Record<string, any>;
  namingConvention: number[];
  setNameField: (id: string, value: string) => void;
  inheritedFields?: (string | number)[];
  granularity: string;
  disabled?: boolean;
}

export const MetaBuyingNameFields = ({
  client,
  agency,
  segmentationDimensionId,
  dimensions,
  nameFields,
  namingConvention,
  setNameField,
  inheritedFields,
  granularity,
  disabled = false,
}: MetaBuyingNameFieldsProps & RouteComponentProps): JSX.Element => (
  <>
    {namingConvention.map(dimensionId => {
      const dimension = dimensions[dimensionId];
      const dimensionType =
        inheritedFields && R.includes(dimensionId, inheritedFields) ? INHERITED : dimension.type;
      let dropdownOptions =
        dimensionType === SEGMENTATION_ID
          ? filterSegmentationIds(nameFields, dimensions, segmentationDimensionId)
          : dimension.options;
      let field;
      let value;

      switch (dimensionType) {
        case AGENCY:
        case CLIENT:
        case CURRENT_DATE:
          value =
            dimensionType === CLIENT
              ? client
              : dimensionType === AGENCY
              ? agency
              : generateFormattedDate(dimension.date_format);
          field = <Form.Control value={value} className="disabled" disabled />;
          break;
        case INHERITED:
          field = (
            <OverlayTrigger
              placement="right center"
              overlay={
                <Tooltip id={`id:${nameFields[dimensionId]}`}>Inherited from {granularity}</Tooltip>
              }
            >
              <Form.Control value={nameFields[dimensionId]} className="disabled" disabled />
            </OverlayTrigger>
          );
          break;
        case SEGMENTATION_ID:
        case SEGMENT_SELECTION:
          field = (
            <DropdownButton
              title={nameFields[dimensionId]}
              onSelect={value => setNameField(dimensionId.toString(), value || "")}
              disabled={dropdownOptions.length === 0 || disabled}
            >
              {dropdownOptions.map(option => (
                <Dropdown.Item key={option} eventKey={option}>
                  {option}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          );
          break;
        default:
          field = (
            <Form.Control
              value={nameFields[dimensionId]}
              onChange={e => setNameField(dimensionId.toString(), e.target.value)}
              className={disabled ? "disabled" : ""}
              disabled={disabled}
            />
          );
          break;
      }
      return (
        <Form.Group key={dimensionId}>
          <Form.Label>{dimension.name}</Form.Label>
          {field}
        </Form.Group>
      );
    })}
  </>
);

export default MetaBuyingNameFields;
