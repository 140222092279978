import { useEffect } from "react";
import { navigate, RouteComponentProps } from "@reach/router";

/**
 * This is a dummy component that will redirect from the Amazon callback to the Retail Ops Channels page.
 */
const AmazonRedirect: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const spapiOauthCode = params.get("spapi_oauth_code");
    const state = params.get("state"); // This should be the company name
    const sellingPartnerId = params.get("selling_partner_id");

    if (!spapiOauthCode || !state || !sellingPartnerId) {
      console.error("Missing required parameters");
    }

    const newUrl2 = `https://blisspoint.tinuiti.com/${state}/retail-ops-channels?spapi_oauth_code=${spapiOauthCode}&selling_partner_id=${sellingPartnerId}`;

    navigate(newUrl2);
  }, []);

  return null;
};

export default AmazonRedirect;
